<style>
    h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/ga-logo-500.png" alt="Gazelle App" style="width: 150px" />
            </div>
        </a>
    </div>
    <h3>¿Quienes somos?</h3>
    <p class="mb-4">
        Gazelle, empresa que brinda un servicio eficiente y puntual de taxi privado a través de su aplicación móvil,
        mediante el cual nuestros usuarios clientes pueden solicitar un vehículo tan solo haciendo un clic. Priorizando
        una solución inmediata y practica para movilizarse sin la necesidad de pagar más y con mayor seguridad.
    </p>
    <p>
        Buscamos ser la solución al transporte público, capacitando a nuestros socios conductores sobre seguridad vial,
        atención al cliente, cumpliendo medidas de bioseguridad y estándares de calidad. Dando un valor agregado para
        superar las expectativas de nuestros clientes.
    </p>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>