<style>
    h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h4 {
        color: #fafafa !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin: 20px 0 12px;
    }

    h5 {
        color: #fafafa !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        margin: 20px 0 12px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/business/pukllay-logo.png" alt="Pukllay" style="width: 300px; border-radius: 20px;" />
            </div>
        </a>
    </div>
    <h3>“Ofrece 8 estilos de Cerveza Artesanal, Cócteles y Piqueos”</h3>
    <h4>Pedidos: 964 323 477</h4>
    <h5>2:00 pm. a 11:00 pm.</h5>
    <div class="spacer"></div>
    <div class="time-counter form-row other-style">
        <div class="hours count col">
            <a href="./assets/docs/business/pukllay.pdf"
                target="_blank" class="btn btn-success" style="margin-bottom:10px">Ver carta digital</a>
        </div>
    </div>
</div>