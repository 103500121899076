<style>
    h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/ga-logo-500.png" alt="Gazelle App" style="width: 150px" />
            </div>
        </a>
    </div>
    <h3>Conductores asociados</h3>
    <p class="mb-4">
        Nuestros socios conductores son capacitados constantemente siguiendo los lineamientos para un servicio seguro y
        de
        calidad.
        Un buen socio conductor cuida al usuario cliente como a una familia.
    </p>
    <div class="spacer"></div>
    <div class="time-counter form-row other-style">
        <div class="hours count col">
            <a routerLink="/driver//policy" class="btn btn-secondary" style="margin-bottom:10px">Ver términos del
                conductor</a>
        </div>
    </div>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>