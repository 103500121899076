<style>
    h3 {
        color: #000000;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .form-box-lg {
        display: inline-block;
        width: 100%;
        /* max-width: 500px; */
        text-align: left;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        background-color: #fafafa;
        padding: 40px 30px;
        color: #000000 !important;
    }

    .form-box-lg h3 {
        color: #222222 !important;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
    }

    .form-box-lg h5 {
        text-align: left !important;
        color: #444444 !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin: 20px 0 12px;
    }

    .form-box-lg p {
        text-align: left !important;
        color: #000000 !important;
        font-size: 14px;
        margin: 0 0 12px 0;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box-lg">

    <h3>TÉRMINOS Y CONDICIONES ASIGNABLE A LOS SOCIOS CONDUCTORES PARA EL USO DE LA APLICACIÓN “GAZELLE DRIVER -
        CONDUCTOR”</h3>

    <p class="mb-3">
        Actualizado el 27 de agosto de 2021.
    </p>
    <p>
        AVISO PARA LOS SOCIOS CONDUCTORES: El Socio Conductor acepta todos los Términos y Condiciones establecidos en el
        presente documento, antes de utilizar la Aplicación Gazelle Driver – conductor (en adelante denominado como
        Aplicación Gazelle Driver), así como cualquier actualización futura de dicha aplicación y/o de las presentes
        políticas. De igual modo, el Socio Conductor acepta las políticas complementarias y/o específicas vigentes que
        pudieren existir para servicios específicos, así como las actualizaciones de las mismas, en el momento que ello
        ocurriere.
    </p>
    <p>
        En el caso que el Socio Conductor no esté de acuerdo con los Términos y Condiciones siguientes, deberá
        abstenerse de utilizar esta aplicación y sus actualizaciones, toda vez que la utilización de la Aplicación
        Gazelle Driver implica manifestación de conformidad del Socio Conductor con todos los Términos y Condiciones. En
        caso el Socio Conductor continúe utilizando la Aplicación Gazelle Driver, se entenderá que las ha aceptado
        completamente.
    </p>
    <p>
        DECLARACIONES DE LOS SOCIOS CONDUCTORES: El Socio Conductor declara y afirma que tiene por lo menos 18 años de
        edad, de acuerdo a lo establecido por el Código Civil Peruano para obligarse con los presentes Términos y
        Condiciones, además de acatarlos y cumplirlos.
    </p>
    <p>I. Al descargar y/o usar la Aplicación Gazelle Driver, usted manifiesta y garantiza lo siguiente: La información
        que brinde a Gazelle sea precisa y veraz.</p>
    <p>II. Tiene pleno conocimiento que los socios conductores no son empleados ni tienen una relación de subordinación
        o laboral con Gazelle.</p>
    <p>III. La utilización de la cuenta de socio conductor es a título personal no siendo posible que sea utilizado por
        un tercero diferente a usted. </p>
    <p>IV. Tiene el derecho, autoridad y capacidad para cumplir con todos los términos y condiciones. </p>
    <p>V. Está en la posibilidad de cumplir por el servicio que acuerde con los usuarios clientes.</p>
    <p>VI. Tiene pleno conocimiento y acepta que Gazelle no es una empresa que brinde o realice servicios de taxi.</p>
    <p>VII. Cuenta con la licencia para conducir vigente en Perú, lugar donde se presta el servicio, obligándose por
        ende a mantenerla vigente y a portarla siempre que brinde los servicios privados de transporte a Usuarios
        Clientes.</p>
    <p>VIII. Se obliga a presentar a Gazelle cada seis (6) meses, o antes, si esta así lo requiriera, su certificado de
        antecedentes penales vigente, a efecto de que se pueda realizar la evaluación correspondiente.</p>
    <p>IX. El vehículo que utiliza para brindar los servicios privados de transporte a los Usuarios Clientes afiliados a
        la Aplicación, está en buenas condiciones para tal fin y se obliga a mantener vigente la cobertura del seguro
        obligatorio de accidentes de tránsito (SOAT) para cubrir daños que pudiesen ocasionarse a usuarios, peatones,
        conductores, personas o terceros, tanto en su persona como en su patrimonio, así como a la infraestructura
        urbana, vial y de movilidad, y en general, de cualquier daño derivado de los servicios de transporte, revisión
        aprobatoria y vigente y/o cualquier otro que le resulte exigible en virtud del servicio prestado.</p>
    <p>X. Se encuentra y se mantendrá en óptimas condiciones de salud para prestar los servicios contratados por los
        Usuarios Clientes, comprometiéndose a que, en caso de que no se encuentre en dichas condiciones, se abstendrá de
        usar la Aplicación para brindar el servicio de transporte.</p>
    <p>XI. No aceptará ni realizará servicios bajo la influencia de drogas, estupefacientes, enervantes, bebidas
        alcohólicas o embriagantes, psicotrópicos o cualquier tipo de sustancia que ponga en peligro o en riesgo sus
        habilidades para conducir adecuadamente el vehículo.</p>
    <p>XII. Respetará las normas de tránsito y, en general, las leyes, reglamentos, disposiciones administrativas y
        demás normativa aplicable de Perú.</p>
    <p>XIII. Todos los pagos hechos en favor de Gazelle, en su caso, en virtud del presente documento, se realizarán con
        recursos de procedencia lícita.</p>
    <p>XIV. En caso de que un Usuario Cliente olvide un objeto dentro de su vehículo, el Socio Conductor deberá
        entregarlo a la brevedad posible en el domicilio de Gazelle para su devolución. De ninguna manera el Socio
        Conductor podrá quedarse con los objetos olvidados ni cobrar una tarifa por regresar el objeto olvidado.</p>
    <p>XV. Respetará las tarifas establecidas que se muestran en la Aplicación y no cobrará montos o cuotas adicionales
        a dichas tarifas o en exceso de dichas tarifas.</p>
    <p>XVI. El vehículo que utiliza y utilizará para la prestación de los servicios a los Usuarios Clientes, es de
        procedencia lícita. </p>

    <h5>1. DEFINICIONES:</h5>
    <p>Para los efectos de los presentes Términos y Condiciones, se deberán tener en cuenta las siguientes definiciones,
        sin importar si se utilizan en forma singular o plural:</p>
    <p>1.1 “Aplicación Gazelle Driver - conductor” significa la aplicación denominada “Gazelle Driver”, que es un
        programa informático que le permite a los Socios Conductores, por medio de sus teléfonos celulares o móviles o
        tabletas aceptar solicitudes de servicios de transporte y/o envíos, requeridos por los Usuarios clientes. </p>
    <p>1.2 “Términos y Condiciones” significa el conjunto de disposiciones y condiciones, contenidas en el presente
        documento, a las que se obligan a cumplir los Socios Conductores, para efectos de utilizar la Aplicación.</p>
    <p>1.3 “Socio Conductor” significa aquel usuario que ha completado el proceso de registro satisfactoriamente, que
        proporciona a título personal el servicio de transporte y/o envío a los Usuarios Clientes y que se ha registrado
        en la aplicación Gazelle con el fin de poder utilizar la “Aplicación de Driver” que le permite aceptar
        solicitudes de servicios realizadas.</p>
    <p>1.4 “Usuario Cliente” significa el cliente que ha completado el proceso de registro, aceptado los Términos y
        Condiciones detallados a continuación y ha sido autorizado por Gazelle para el uso de la Aplicación, (servicio
        de viajar o servicio envío).</p>
    <p>1.5 “Servicio de Interconexión digital” significa el servicio prestado, únicamente, al Socio Conductor; mediante
        este servicio, el Socio Conductor conecta con el Usuario Cliente. Por este servicio, se efectúa el pago de una
        comisión del Socio Conductor a favor de Gazelle.</p>

    <h5>2. AUTORIZACIÓN DE USO DE LA APLICACIÓN, REQUERIMIENTOS TÉCNICOS Y RESTRICCIONES DE USO:</h5>
    <p>2.1 Durante la vigencia de los presentes Términos y Condiciones, el Socio Conductor tendrá el derecho no
        exclusivo, revocable e intransferible de utilizar la Aplicación exclusivamente para las funciones que se
        establecen más adelante en la sección 3.</p>
    <p>2.2 Toda la información que facilite el Socio Conductor a Gazelle deberá ser veraz. A estos efectos, el Socio
        Conductor garantiza la autenticidad de todos aquellos datos que comunique como consecuencia del llenado y
        posterior envío a Gazelle de los formularios necesarios para la suscripción del servicio. De igual forma, el
        Socio Conductor será responsable de mantener toda la información facilitada a Gazelle a través de la Aplicación,
        permanentemente actualizada de forma que responda, en cada momento, a su situación real y actual. Asimismo, el
        Socio Conductor será el único responsable de las manifestaciones falsas o inexactas que realice y de los
        perjuicios que cause a Gazelle y/o a terceros por la información que facilite.</p>
    <p>2.3 El Socio Conductor será responsable de contar con un teléfono celular o móvil o tableta que cumpla con las
        características y requerimientos técnicos para acceder y utilizar la Aplicación, incluyendo la conexión a
        Internet. El correcto funcionamiento de la Aplicación está sujeto a ello. El Socio Conductor asumirá y será
        responsable del costo de su servicio telefónico y de Internet, y deberá consultar con las empresas de
        telecomunicaciones respectivas las tarifas por el uso de planes de voz y/o de datos.</p>
    <p>2.4 No se podrán registrar desde un mismo número telefónico más de un Socio Conductor.</p>
    <p>2.5 Usted es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para
        acceder y utilizar los Servicios y cualquier actualización que GAZELLE realice a la aplicación.</p>
    <p>2.6 El Socio Conductor se obliga a no modificar, reproducir, copiar, realizar ingeniería inversa, revertir la
        ingeniería, rediseñar, descompilar, adaptar, traducir, preparar trabajos derivados de la Aplicación o usar la
        Aplicación para desarrollar cualquier software u otros materiales basados en el mismo. Asimismo, se obliga a
        utilizar la Aplicación únicamente en la forma permitida en estos Términos y Condiciones. Gazelle declara que la
        Aplicación se encuentra protegida por la legislación vigente en materia de Derechos de Autor, la misma que el
        Socio Conductor declara y afirma conocer y se compromete a cumplir a cabalidad.</p>
    <p>2.7 El Socio Conductor se obliga a respetar, velar y proteger los datos personales de los Usuarios Clientes que
        acepten los servicios, por lo que se compromete a utilizar dicha información única y exclusivamente para
        contactarlos con la finalidad de concretar el servicio de transporte.</p>
    <p>2.8 El Socio Conductor acepta y reconoce que Gazelle podrá realizar acciones legales para requerir el
        cumplimiento de todas las disposiciones establecidas en los presentes Términos y Condiciones, inclusive con
        aquellas referidas a la protección de los datos personales de los Usuarios Clientes. Asimismo, el Socio
        Conductor se compromete a desechar cualquier información y/o dato que pueda mantener en su teléfono celular o
        móvil al culminar los servicios prestados, caso contrario, reconoce que Gazelle podrá solicitarle el
        resarcimiento por daños y perjuicios ocasionados por incumplimiento de sus obligaciones, incluyendo tal concepto
        la indemnización por daño emergente y lucro cesante.</p>
    <p>2.9 El Socio Conductor es el único y exclusivo responsable del uso que le dé a la Aplicación, así como de
        cualquier acción que tenga lugar mediante o a raíz de dicho uso y, en tal sentido, no cabe imputación de
        responsabilidad alguna, ni directa ni indirectamente, contra Gazelle. </p>
    <p>2.10 El Socio Conductor reconoce el derecho de Gazelle a proceder inmediatamente y sin notificación previa, a
        retirarlo de la Aplicación y/o a bloquear su acceso a éste, en el caso de que el uso que le dé a la Aplicación
        sea inadecuado, ilícito, abusivo o viole los presentes Términos y Condiciones, sus documentos conexos y/o sus
        actualizaciones, los derechos de terceros, las leyes y los reglamentos vigentes.</p>
    <p>2.11 En caso de que se asocien las cuentas de los Socio Conductores de la Aplicación a cuentas de Facebook o
        Gmail, el Usuario Conductor autoriza expresamente el uso de su foto de perfil de Facebook (también denominado
        para estos efectos, “Avatar”) para un mejor reconocimiento del mismo por parte del Usuario Cliente.</p>
    <p>2.12 Durante la emergencia sanitaria declarada por el gobierno peruano a propósito de la pandemia mundial
        denominada COVID-19, el Socio Conductor se compromete a respetar las recomendaciones en materia de seguridad
        sanitaria emitidas por el Ministerio de Salud. Adicionalmente, el Socio Conductor declara y se obliga a lo
        siguiente:</p>
    <p>(a) Usar el aplicativo siempre que NO hubiera sido diagnosticado con COVID-19, tuviera algún síntoma
        característico de COVID-19 o bien se encuentre a la espera de la entrega de sus resultados por parte de la
        entidad de salud autorizada.</p>
    <p>(b) Usar una mascarilla durante el uso del aplicativo</p>
    <p>(c) Proveer ventilación natural al vehículo. Esto es, NO usar aire acondicionado sino por el contrario, mantener
        las ventanas abiertas en todo momento.</p>
    <p>(d) Permitir el uso de tres (03) pasajeros como máximo.</p>
    <p>2.13 En caso de que el Socio Conductor viole la legislación o infrinja los derechos de terceros y/o exista duda
        razonable de ello, Gazelle tiene derecho a facilitar, previa solicitud de cualquier autoridad legítima
        (tribunales, autoridades administrativas, fiscales y/o las fuerzas policiales), cualquier información que
        permita o facilite la identificación del infractor. En el supuesto que el afectado sea un Usuario Cliente,
        Gazelle facilitará los datos a su alcance con el fin de que el afectado pueda iniciar las acciones legales
        correspondientes, condición que es aceptada por el Socio Conductor al aceptar estos términos y condiciones. </p>

    <h5>3. FUNCIONES DE LA APLICACIÓN:</h5>
    <p>3.1 Por medio de la Aplicación, el Socio Conductor podrá recibir solicitudes de servicio de transporte y/o envío
        de los Usuarios Clientes afiliados a la Aplicación. Cada una de dichas solicitudes contendrá el nombre,
        ubicación y otra información que el Usuario Cliente pueda indicar (ej. Referencias), a efectos que el Socio
        Conductor pueda identificarlo, así como aceptar o rechazar libremente su solicitud de servicio. Asimismo, el
        Socio Conductor reconoce que la información que reciba de los Usuarios Clientes se trata de Datos Personales,
        por lo que a su vez se constituye como único responsable del manejo de la misma y se compromete a utilizarla
        exclusivamente a efectos de prestar el servicio.</p>
    <p>3.2 Los Socios Conductores son independientes y libres de aceptar o rechazar las solicitudes de servicios que
        envíe el Usuario Cliente, incluso si los Socios Conductores aparecen como disponibles en la Aplicación. Al
        respecto, por medio de los presentes Términos y Condiciones, queda establecido que ni Gazelle ni su Aplicación
        Gazelle Driver son una empresa de taxi o “radio taxi” ni presta un servicio de transporte de pasajeros, ni posee
        una flota de vehículos para transportar pasajeros, ni proporciona servicios de courier, ni es un sitio web de
        taxi. Gazelle únicamente autoriza el uso de la Aplicación Gazelle Driver a efectos que el Socio Conductor pueda
        conectarse a través de la misma con los Usuarios Clientes que utilicen la Aplicación para brindar sus servicios
        de transporte y/o envío. Asimismo, Gazelle no tiene una relación laboral, de subordinación o exclusividad con
        los Socios Conductores.</p>
    <p>Sin perjuicio de lo anterior, el Socio Conductor reconoce y se compromete obligatoriamente a cumplir con el
        servicio requerido por el Usuario Cliente una vez aceptada la solicitud de servicio, salvo caso fortuito o
        fuerza mayor. De igual manera, se compromete y asume la responsabilidad de pagarle a Gazelle las comisiones
        correspondientes por el servicio realizado, de acuerdo con lo establecido en la sección 4.1 siguiente.</p>
    <p>3.3 El Socio Conductor, podrá elegir grabar audio del dispositivo durante el viaje con la aplicación, así como
        utilizar la grabación para enviar un reporte al equipo de soporte de Gazelle si algo le hace sentir incómodo en
        el camino. El audio es un recurso adicional para que, en caso de tener reporte de un incidente, podamos
        comprender lo ocurrido y tomar las medidas pertinentes. ¿Cómo funciona esta herramienta? La opción para comenzar
        a grabar el audio se encuentra en las opciones del viaje en curso. Al pulsar “Grabar audio del dispositivo”,
        haber aceptado los términos de uso y dar el permiso de grabar audio del dispositivo, al presionar clic en el
        botón “Iniciar grabación”, se ejecuta un proceso en segundo plano y empezará a grabar el audio, este será
        detenido de forma manual, en caso de haber cerrado y sin detener la grabación no será posible grabar el audio,
        una vez que se haya detenido la grabación de forma correcta, será enviado al equipo de soporte de Gazelle, solo
        se hará uso si el Socio Conductor lo autoriza.</p>
    <p>3.4 El Socio Conductor puede subir una foto de perfil a la aplicación, para lo cual esta se solicitará el permiso
        de acceso a los archivos del dispositivo. Esta opción es obligatoria para el Socio Conductor ya que se requiere
        que los Usuarios Clientes y el equipo de soporte de Gazelle App puedan identificarlo correctamente en el perfil
        de su cuenta. La foto de perfil del Socio Conductor debe ser de tipo “foto carnet” o “foto pasaporte”, no estar
        con anteojos, gorra o cualquier otro accesorio que oculte parte de su rostro.</p>
    <p>3.5 El Socio Conductor también podrá calificar, publicar comentarios y opiniones respecto de los Usuarios
        Clientes que se contacten con ellos a través de la Aplicación. Al respecto, el Socio Conductor se obliga a que
        la información que transmita o publique a través de la Aplicación:</p>
    <p>(a) Cumplirá con todas las normas y leyes vigentes de la jurisdicción en la que el Socio Conductor esté ubicado y
        del Estado Peruano;</p>
    <p>(b) No será falsa, engañosa, calumniosa, injuriosa, difamatoria, obscena, pornográfica, amenazante o fraudulenta;
    </p>
    <p>(c) No violará los derechos de propiedad intelectual de terceros; y</p>
    <p>(d) No será propagandas o anuncios políticos, comerciales, religiosos ni incluirá correos electrónicos masivos o
        spams.</p>
    <p>3.6 Es importante mencionar y advertir que, a través de la Aplicación de Gazelle Cliente, el Usuario Cliente al
        que el Socio Conductor decida prestarle servicios podrá calificar, hacer comentarios y publicar opiniones sobre
        el servicio y sus comentarios, calificaciones, opiniones y evaluaciones. El promedio de la calificación de los
        Socio Conductores será compartido con Usuarios Clientes.</p>
    <p>3.7 Por medio de los presentes Términos y Condiciones queda establecido que Gazelle no es responsable de los
        comentarios, opiniones y calificaciones que otorgue el Socio Conductor, otros Socios Conductores o los Usuarios
        Clientes con relación a los servicios. Asimismo, el Socio Conductor reconoce que asume el riesgo respecto de la
        honradez, solvencia y/o cumplimiento de los Usuarios Clientes, no siendo posible imputar a Gazelle
        responsabilidad alguna al respecto ni efectuar reclamo alguno con relación a este concepto.</p>
    <p>3.8 Gazelle se reserva el derecho de bloquear, temporal o definitivamente, el acceso de Socios Conductores o
        remover en forma parcial o total, toda información, comunicación o material, incluyendo opiniones, comentarios o
        calificaciones que el Socio Conductor, otros Socios Conductores, Usuarios Clientes y/o usuarios en general de la
        Aplicación Gazelle Driver o la Aplicación Gazelle Cliente publiquen a través de las Aplicaciones, de las Redes
        Sociales (Facebook, Instagram, página web de Gazelle, entre otros similares) u otro medio de comunicación o
        espacio generado por Gazelle:</p>
    <p>(a) Que esté protegido por derechos de propiedad intelectual, protegidas por el secreto comercial o marca
        comercial, o de otra manera impliquen los derechos de propiedad de terceros, incluidos los derechos de
        privacidad y publicidad, a menos que usted sea el propietario de dichos derechos o tenga permiso o una licencia
        de su legítimo propietario para publicar el material.</p>
    <p>(b) Que sea dañino, abusivo, ilegal, amenazante, acosador, difamatorio, pornográfico, calumnioso, invasivo de la
        privacidad, que dañe o pueda dañar a menores de cualquier manera.</p>
    <p>(c) Que acose, degrade, intimide o sea odioso hacia un individuo o grupo de individuos sobre la base de la
        religión, el género, la orientación sexual, la raza, la etnia, la edad o la discapacidad.</p>
    <p>(d) Que discrimine por razón de raza, etnia, religión, sexo, origen nacional, edad, discapacidad u otro motivo
        que afecte la dignidad de las personas o terceros.</p>
    <p>(e) Que incluya información personal o de identificación de otra persona sin el consentimiento expreso de esa
        persona.</p>
    <p>(f) Que imite a otra persona o entidad, incluyendo, pero no limitado a, un empleado de Gazelle, o falsa
        declaración o distorsione su afiliación con una persona o entidad.</p>
    <p>(g) Que constituya o contenga “esquemas piramidales”, “marketing de afiliación”, “código de referencia a enlace”,
        “correo basura”, “spam”, “cartas en cadena”, o anuncios publicitarios no solicitados de carácter comercial.</p>
    <p>(h) Que haga referencia o incluya enlaces a servicios comerciales o sitios Web de terceros.</p>
    <p>(i) Que publicite servicios ilícitos o la venta de cualquier artículo cuya venta está prohibida o restringida por
        la ley aplicable. </p>
    <p>(j) Que contenga virus de software o cualquier otro código de computadora, archivos o programas diseñados para
        interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware o equipo de
        telecomunicaciones.</p>
    <p>(k) Que interrumpa el flujo normal del diálogo con una cantidad excesiva de mensajes (ataque masivo) al Servicio,
        o que afecte en forma negativa la capacidad de otros usuarios para utilizar el Servicio.</p>
    <p>3.9 Si el Socio Conductor desea obtener mayor información acerca de un Usuario Cliente en particular, deberá
        consultarlo con Gazelle, quien evaluará dicho requerimiento de modo tal que no incumpla la legislación vigente
        para entregar dicha información y, de ser el caso, solicitará el traslado de aquella información a su titular.
    </p>
    <p>3.10 Se considerará que El Socio Conductor no presta los servicios a los Usuarios Clientes de buena fe, si
        realiza alguno de los siguientes actos:</p>
    <p>(a) Divulgación de cualquier información imprecisa o incompleta, o que no haya sido proporcionada mediante la
        app;</p>
    <p>(b) Dejar al Usuario Solicitante a la mitad del viaje, o realizar el viaje en towards;</p>
    <p>(c) Desviarse intencionalmente del punto de destino;</p>
    <p>(d) Cobrar cuotas ilegalmente o montos fuera de la app.</p>

    <h5>4. COMISIONES:</h5>
    <p>4.1 De acuerdo con los alcances de los presentes Términos y Condiciones, por cada servicio realizado a través de
        la Aplicación, el Socio Conductor deberá pagar a Gazelle una comisión por el uso de la Aplicación. Los montos
        aplicables serán establecidos por Gazelle y podrán ser modificados, siendo debida y previamente comunicados al
        Socio Conductor.</p>
    <p>Los pagos correspondientes deberán realizarse en el plazo estipulado por Gazelle.</p>
    <p>4.2 Gazelle llevará un registro de todas las solicitudes de servicio que el Socio Conductor acepte a través de la
        Aplicación. En virtud de las mismas se calculará el monto por comisiones que el Socio Conductor deba pagar a
        Gazelle por la utilización de la Aplicación.</p>
    <p>4.3 La obligación de pago de las comisiones se genera automáticamente cuando el Socio Conductor declara,
        presionando el botón “aceptar” o similar, su aceptación para la realización del servicio solicitado por un
        Usuario Cliente. A partir de tal momento, el Socio Conductor queda irrevocable e incondicionalmente obligado a
        realizar el pago referido, independientemente de si el Socio Conductor completa o no el servicio o de si el
        Usuario Cliente paga o no el servicio. </p>
    <p>La obligación de pago referida en el párrafo anterior no será de aplicación en los casos en que el Usuario
        Solicitante cancele la solicitud de servicio antes que el Usuario Conductor llegue a la dirección indicada para
        dar inicio al servicio.</p>
    <p>4.4 Gazelle entregará de manera semanal, un registro correspondiente a las comisiones abonadas o pagadas a
        Gazelle, más el monto por concepto de cuota de servicio por parte del usuario conductor, para lo cual Gazelle
        realizará una liquidación semanal de los servicios prestados, que incluyen los realizados con pago en efectivo,
        cupones y billeteras digitales.</p>
    <p>Gazelle queda facultado a revisar cada uno de los servicios para identificar la correcta realización de los
        mismos. De encontrar algún comportamiento fraudulento, el servicio podrá ser calificado como inválido y podrá
        excluir todas aquellas ganancias identificadas como fraudulentas de los Socios Conductores. Asimismo, Gazelle se
        reserva el derecho de tomar las acciones legales correspondientes ante cualquier comportamiento fraudulento
        identificado.</p>
    <p>4.5 Será responsabilidad del Socio Conductor, verificar que los montos señalados en los registros sean los
        correspondientes a la semana de liquidación.</p>
    <p>4.6. El Socio Conductor comprende y acepta que el monto correspondiente a “cuota de servicio” que visualiza en la
        tarifa no se adiciona como parte de la ganancia de éste. La “cuota de servicio” es un monto que será trasladado
        y será asumido directamente por el Usuario Cliente. </p>
    <p>El concepto correspondiente a “cuota de servicio” es un concepto asociado a los costos operacionales para el
        desarrollo y mantenimiento de la tecnología necesaria para el buen funcionamiento de la Aplicación, que es
        utilizada por GAZELLE para prestar sus servicios a los Socios Conductores.</p>
    <p>4.7. Los cargos realizados pueden incluir otras tarifas, y/o cargos adicionales, incluidas la cuota de servicio,
        recargo por o cualquier otra tarifa a ser aplicada de acuerdo a disposiciones gubernamentales.</p>

    <h5>5. PROHIBICIÓN DE DISTRIBUCIÓN Y TRANSFERENCIA:</h5>
    <p>5.1 El Socio Conductor no podrá distribuir, arrendar, otorgar licencias, ceder ni transferir sus derechos de uso
        de la Aplicación, ni autorizar su copia total o parcial en el equipo de otro socio. Asimismo, bajo ningún motivo
        pondrá la Aplicación a disposición de otras personas, ni en un servidor host, oficina de servicio o similar, ni
        a través de Internet o de cualquier otro modo.</p>
    <p>5.2 El Socio Conductor no podrá utilizar la Aplicación en un teléfono celular, móvil o tableta distinta al
        dispositivo en el que inicialmente descargó la Aplicación. En caso de cambio o extravío, el Usuario Conductor
        deberá notificarlo a Gazelle para cambiar su registro y autorizar otra descarga de la Aplicación. El Socio
        Conductor no deberá permitir que terceros distintos a él acepten y realicen los viajes o servicios desde la
        cuenta con la cual que se registró en la aplicación. Es responsabilidad del Socio Conductor el uso indebido de
        la Aplicación que sea generado producto del incumplimiento de los Términos y Condiciones.</p>

    <h5>6. DERECHOS DE PROPIEDAD INTELECTUAL:</h5>
    <p>6.1 Gazelle, sus proveedores y filiales son licenciatarios de la propiedad intelectual de la Aplicación,
        incluyendo los derechos de autor sobre el código fuente y el código objeto de la Aplicación y los derechos sobre
        todas las marcas, logos, slogans, diseños, imágenes, videos, información, música, sonidos, nombres comerciales y
        otra Propiedad Intelectual e Industrial que forme parte del contenido de la Aplicación. En consecuencia, el
        Socio Conductor deberá respetar los derechos de propiedad intelectual de Gazelle, por lo que se encuentra
        prohibido y se compromete expresamente a no copiar, reproducir, modificar, alterar, distribuir y/o transferir la
        Aplicación, reconociendo expresamente que dicha propiedad intelectual deberá ser utilizada únicamente para los
        fines establecidos en los presentes Términos y Condiciones.</p>
    <p>Queda expresamente establecido que Gazelle no será responsable por los daños que sufra un Socio Conductor que
        hubiese copiado, reproducido, modificado, alterado, distribuido y/o transferido la Aplicación o el contenido de
        la misma que se encuentre protegido por derechos de autor y propiedad intelectual.</p>
    <p>6.2 Los presentes Términos y Condiciones regulan la autorización de uso de la Aplicación por parte de los Socios
        Conductores, mas no su venta. Así, Gazelle autoriza al Socio Conductor, en forma no exclusiva ni transferible, a
        utilizar la Aplicación únicamente para los fines establecidos en el presente documento. En consecuencia, los
        presentes Términos y Condiciones no implican ningún tipo de transferencia de la propiedad de la Aplicación o de
        sus derechos.</p>
    <p>6.3 El Socio Conductor no ha abonado contraprestación alguna por el uso de los derechos de Propiedad Intelectual
        de Gazelle y ninguna de las disposiciones de los presentes Términos y Condiciones le dará derecho alguno al
        Socio Conductor sobre los derechos de Propiedad Intelectual de Gazelle. El Socio Conductor no utilizará dentro
        del territorio las marcas, nombres comerciales, logotipos, denominaciones sociales, nombres de dominio, signos
        distintivos u otras designaciones ni intentará registrar las marcas, nombres comerciales, logotipos,
        denominaciones sociales, nombres de dominio, signos distintivos u otras designaciones que pudieran causar
        confusión con los derechos de Propiedad Intelectual de Gazelle.</p>
    <p>6.4 El Socio Conductor reconoce que Gazelle es licenciataria de todos los derechos de Propiedad Intelectual, por
        lo que acuerda no reclamar en ningún momento durante la vigencia de estos Términos y Condiciones ni con
        posterioridad a ellos ningún derecho ni hacer que afecte la validez de cualquiera de los derechos de Propiedad
        Intelectual de Gazelle (incluyendo, sin que implique limitación alguna, cualquier acto o asistencia que pudiera
        violar o causar la violación de cualquiera de los derechos patrimoniales de Gazelle). El Socio Conductor acuerda
        adherirse a las políticas e instrucciones de Gazelle respecto del uso de los derechos de Propiedad Intelectual
        de Gazelle, conforme pudieran ser publicados o comunicados en forma periódica.</p>

    <h5>7. ACTUALIZACIONES DE LA APLICACIÓN:</h5>
    <p>7.1 Gazelle podrá libremente, no siendo ello una obligación, poner a disposición del Socio Conductor,
        actualizaciones de la Aplicación Gazelle Driver para su descarga. Queda establecido que la descarga de alguna de
        las actualizaciones implica que el Socio Conductor renuncia voluntariamente a su derecho de utilizar cualquier
        versión anterior de la Aplicación.</p>
    <p>7.2 La Aplicación ha sido programada de tal modo que es capaz de conectarse automáticamente a Internet y buscar
        actualizaciones disponibles, las cuales pueden descargarse automáticamente, siempre y cuando la configuración
        del dispositivo así lo permita.</p>
    <p>7.3 Gazelle se reserva el derecho de suprimir y/o modificar el contenido de la Aplicación, cuando así lo
        considere necesario, a efectos de cumplir con los estándares comerciales de Gazelle así como con las
        disposiciones legales que fuesen aplicables. </p>

    <h5>8. CONEXIÓN A INTERNET Y SERVICIOS EN LÍNEA:</h5>
    <p>8.1 La Aplicación requiere estar conectada a Internet para su funcionamiento. Asimismo, es capaz de hacer que su
        equipo (teléfono celular, móvil o tableta) se conecte automáticamente a Internet para notificar a Gazelle que la
        Aplicación se ha instalado correctamente y que se encuentra en adelante habilitada para utilizar sus funciones,
        así como recibir solicitudes de servicios de Usuarios Clientes. </p>
    <p>De igual modo, requiere contar con la función de GPS activada, en este sentido la aplicación solicitará al Socio
        Conductor activar al GPS de su dispositivo, así como el acceso a su ubicación actual. Esto con la finalidad de
        poder ubicar al conductor en una zona específica y determinar la cobertura del servicio si su ubicación actual
        está dentro de la misma.</p>
    <p>Así también cada vez y solo cuando el Socio Conductor tenga activado su disponibilidad con la opción “Activar
        disponibilidad” y mientras esté en curso de un viaje, la aplicación tendrá acceso a su ubicación cada 20
        segundos para poder hacer el seguimiento del viaje, esto con la finalidad de monitorear el viaje en caso de
        presentarse alguna eventualidad fuera del protocolo del servicio como accidentes, problemas técnicos entre
        otros. La aplicación solo tendrá acceso a la ubicación del Socio Conductor solo cuando tenga activada su
        disponibilidad para el servicio de Gazelle, si el Socio Conductor desactiva su disponibilidad, la aplicación no
        tendrá acceso a esta información.</p>
    <p>Cuando la Aplicación se conecta automáticamente a Internet, se envía a un sitio web de Gazelle una dirección de
        protocolo de Internet asociada a su conexión de Internet actual.</p>

    <h5>9. RENUNCIA DE GARANTÍAS:</h5>
    <p>9.1 El Socio Conductor que reconoce que Gazelle le autoriza el uso de la Aplicación en el estado en el que se
        encuentra y como fue proporcionada por el creador, quien es propietario o titular de la Aplicación. El Socio
        Conductor confirma que Gazelle no ofrece ningún tipo de garantía de su funcionamiento.</p>
    <p>9.2 Por medio del presente documento, el Socio Conductor reconoce que asume el riesgo y responsabilidad respecto
        de cualquier tipo de daño que pudiese sufrir como consecuencia del incumplimiento, hecho, acto u omisión por
        parte los Usuarios Clientes que requieran servicios a través de la Aplicación. Esta disposición se debe
        fundamentalmente a que la relación entre el Socio Conductor y los Usuarios Clientes es completamente
        independiente de Gazelle. En consecuencia, Gazelle no es bajo ninguna circunstancia responsable, ni directa ni
        indirectamente, por daños, perjuicios, reclamaciones, quejas, pérdidas o costos de cualquier tipo derivados del
        uso de la Aplicación por parte del Socio Conductor, ya que únicamente actúa como intermediario entre los
        usuarios</p>
    <p>9.3 Gazelle no hace declaración o garantía alguna sobre el contenido de la Aplicación ni la veracidad, integridad
        o exhaustividad de la información contenida en la Aplicación o la información que proporcionen otros Socios
        Conductores o Usuarios Clientes, incluyendo información respecto a la honorabilidad, ubicación e identidad de
        algún Usuario Cliente. La información, consejos, conceptos, calificaciones, evaluaciones y opiniones publicadas
        en la aplicación no necesariamente reflejan la posición de Gazelle, ni de sus empleados, oficiales, directores,
        accionistas, licenciatarios, licenciantes, proveedores y afiliadas. Por esta razón, Gazelle, no se hace
        responsable por alguna información, imagen, audio, video, consejos, opiniones y conceptos que se emitan o
        publiquen en la Aplicación, sus redes sociales, web, y cualquier otro espacio que permita la interacción entre
        Usuarios, y entre Usuarios y terceros.</p>
    <p>9.4 Gazelle no se responsabiliza por cualquier daño, perjuicio o pérdida al Socio Conductor causados por fallas
        en el sistema, en el servidor o en Internet. Gazelle tampoco será responsable por cualquier virus que pudiera
        infectar los equipos del Socio Conductor como consecuencia del acceso, uso o examen de su sitio web o a raíz de
        cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo. Los Socios
        Conductores NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios
        resultantes de dificultades técnicas o fallas en los sistemas o en Internet. Gazelle no garantiza el acceso y
        uso continuado o ininterrumpido de la Aplicación. El sistema puede eventualmente no estar disponible debido a
        dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a Gazelle; en tales casos
        se procurará restablecerlo con la mayor celeridad posible, sin que por ello pueda imputársele algún tipo de
        responsabilidad.</p>
    <p>9.5 Sin perjuicio de las cláusulas anteriores, Gazelle no garantiza:</p>
    <p>(a) La disponibilidad de la Aplicación por problemas técnicos imputables a sistemas de comunicación y conexión a
        Internet;</p>
    <p>(b) La ausencia de virus, errores, desactivadores o materiales contaminantes o con funciones destructivas en la
        Aplicación; o</p>
    <p>(c) Que el Socio Conductor vaya a recibir un número determinado de solicitudes de servicio </p>

    <h5>10. DECLARACIONES E INDEMNIZACIÓN:</h5>
    <p>10.1 Al aceptar los presentes Términos y Condiciones, descargar la Aplicación y cada vez que el Socio Conductor
        acepte una solicitud de servicios a través de la Aplicación, se entenderá automáticamente que el Socio Conductor
        declara lo siguiente:</p>
    <p>I. Se obliga a presentar a Gazelle al momento de su registro sus antecedentes vigentes, a efectos que se pueda
        realizar la evaluación correspondiente, y autoriza a Gazelle, para que, en su defecto, pueda encargarle a un
        tercero (proveedor de servicios) la obtención de la información pública que exista sobre sus antecedentes
        policiales, penales y judiciales.</p>
    <p>II. El vehículo que utiliza para brindar los servicios está en buenas condiciones para tal fin y en cumplimiento
        de las normas aplicables al servicio prestado. Asimismo, mantendrá la licencia de conducir registrada y la
        cobertura del Seguro Obligatorio de Accidentes de Tránsito (SOAT), revisión técnica aprobatoria y vigente y/o
        cualquier otro que le resulte exigible en virtud del servicio prestado.</p>
    <p>III. Se encuentra y se mantendrá en óptimas condiciones de salud para prestar los servicios contratados por los
        Usuarios Clientes, comprometiéndose a que, en caso no se encuentre en dichas condiciones, se abstendrá de usar
        la aplicación para brindar el servicio de transporte.</p>
    <p>IV. No aceptará ni realizará servicios bajo la influencia de drogas, estupefacientes, enervantes, bebidas
        alcohólicas o embriagantes, psicotrópicos o cualquier tipo de sustancia que ponga en peligro o en riesgo sus
        habilidades para conducir adecuadamente el vehículo.</p>
    <p>V. Respetará las normas de tránsito y, en general, las leyes de la República del Perú.</p>
    <p>VI. Todos los pagos realizados a favor de Gazelle en virtud del presente documento se realizarán con recursos de
        procedencia lícita.</p>
    <p>VII. El vehículo que utiliza y utilizará para la prestación de los servicios es de procedencia lícita.</p>
    <p>VIII. Respetará las tarifas establecidas, que se muestran en la Aplicación, y no cobrará montos o cuotas
        adicionales a dichas tarifas o en exceso de dichas tarifas, sólo podrá incluir costos adicionales siempre y
        cuando hayan sido acordados previamente con el Usuario Cliente.</p>
    <p>IX. Qué; para el caso del servicio “GAZELLE envío”, no aceptará ninguna solicitud que haga referencia al
        transporte de bienes valiosos (i) Materiales y Residuos Peligrosos; (ii) Psicotrópicos y Estupefacientes; (ii)
        Armas de Fuego y Explosivos; (iv) Animales; (v) Dinero o Títulos de Crédito al Portador o Negociables; (vi)
        Cualquier producto que no cuente con el permiso específico o bien, lo restrinja alguna ley; (vii) Artículos que
        rebasen las dimensiones de carga del vehículo (sofá, escaleras, mesas, etc.)</p>
    <p>X. Prestará los servicios en forma diligente y será responsable de su reputación frente a los Usuarios Clientes
        que utilicen la Aplicación.; y,</p>
    <p>XI. Se compromete a no realizar gestiones para captar como clientes personales a los Usuarios Clientes que
        transporte mediante el uso de la Aplicación Gazelle Driver.</p>
    <p>10.2 Queda expresamente establecido que el servicio realizado a través de la Aplicación constituye una relación
        jurídica autónoma e independiente de la existente entre el Socio Conductor y el Usuario Cliente que requiere el
        servicio del primero. En virtud de ello, cualquier disputa, conflicto, delito, controversia y/o negociación en
        general deberá ser resuelta directamente entre el Socio Conductor y el Usuario Cliente, no formando Gazelle
        parte de tal relación.</p>
    <p>El Socio Conductor acepta, reconoce y se obliga expresamente a proteger y mantener a Gazelle fuera de cualquier
        tipo de conflicto o controversia que resulte del uso de la Aplicación por el Socio Conductor o de un
        incumplimiento por parte del Socio Conductor respecto de cualquier tipo de obligación legal y/o asumida en los
        presentes Términos y Condiciones, asumiendo el Socio Conductor total responsabilidad de ello, al ser Gazelle
        únicamente un intermediario entre los usuarios. El alcance de la presente cláusula comprende el derecho de
        Gazelle a recibir la indemnización que fuese aplicable en caso se le produzca algún daño o perjuicio producto de
        las situaciones descritas.</p>
    <p>10.3 El Socio Conductor asume la total responsabilidad de cualquier suceso, evento, circunstancia o daño que se
        produzca o pueda producir, durante los servicios que se hayan originado del uso de la Aplicación. Al respecto,
        reconoce expresamente que Gazelle únicamente autoriza el uso de la Aplicación Gazelle para que el Socio
        Conductor de modo libre e independiente, pueda conectarse con Usuarios Clientes que requieran los servicios a
        través de la Aplicación Gazelle. En tal sentido, Gazelle no será responsable, ni directa ni indirectamente, de
        ningún suceso, evento, circunstancia o daño que pueda generarse durante los servicios referidos, al ser
        únicamente el intermediario entre los usuarios.</p>
    <p>10.4 Al aceptar los presentes Términos y Condiciones, el Socio Conductor se compromete a no realizar ninguna de
        las siguientes acciones:</p>
    <p>I. Utilizar la Aplicación para alguna finalidad distinta a la establecida en el presente documento, y en general,
        para cualquier fin ilegal que perjudique, genere daños o pueda potencialmente perjudicar o generar daños a
        Gazelle. En caso se necesite la manifestación del Socio Conductor, respecto a cualquier denuncia o reclamo que
        Gazelle reciba por parte de Usuarios Clientes o terceros que se hayan podido ver perjudicados por algún tipo de
        contingencia, el Socio Conductor deberá acercarse a las oficinas de Gazelle para firmar una Declaración Jurada
        en la cual, deberá relatar al detalle el incidente sucedido. Se indica que el documento firmado como Declaración
        Jurada debe contener hechos veraces, ya que este documento podrá ser presentado ante la autoridad pertinente
        cuando ésta la requiera, por lo que, en caso de contener información falsa, se podrán iniciar las acciones
        penales pertinentes. </p>
    <p>II. Transferir archivos que contengan virus, troyanos, y otros programas que afecten o puedan perjudicar el
        funcionamiento de la Aplicación. </p>
    <p>III. Acceder o intentar acceder a cuentas de otros usuarios o penetrar o intentar penetrar cualquier medida de
        seguridad.</p>
    <p>IV. Publicar y/o difundir cualquier información que pueda difamar, ser obscena, amenazar, acosar, discriminar o
        ser abusiva para un individuo o grupo de individuos, teniendo especial consideración con información relacionada
        a, por ejemplo, la religión, género, orientación sexual, raza, etnia, edad y discapacidad.</p>
    <p>V. Promover sus servicios individuales o cualquier tipo de producto y/o servicio en general por medio de la
        Aplicación o durante la realización de servicios a los Usuarios Clientes.</p>

    <h5>11. LEY APLICABLE Y JURISDICCIÓN:</h5>
    <p>Los presentes Términos y Condiciones se regirán por las leyes de la República del Perú. Cualquier disputa o
        controversia relacionada con estos Términos y Condiciones se resolverá en los tribunales competentes. Ambas
        partes renuncian a cualquier otra jurisdicción que pudiere corresponderles.</p>

    <h5>12. PLAZO Y TERMINACIÓN:</h5>
    <p>12.1 Estos Términos y Condiciones permanecerán vigentes por tiempo indefinido.</p>
    <p>El Socio Conductor o Gazelle podrán terminar la presente relación jurídica en cualquier momento, mediante simple
        notificación por correo electrónico, aviso a través de su Aplicación o por escrito entregada a la otra parte con
        al menos 24 (veinticuatro) horas de anticipación a la terminación. La resolución de los Términos y Condiciones
        implicará que el derecho y autorización del Socio Conductor para utilizar la Aplicación quedará sin efecto, por
        lo que este se compromete a eliminar la Aplicación de su teléfono celular, móvil o tableta en el momento en que
        este documento fuese resuelto.</p>
    <p>12.2 Sin perjuicio de lo establecido en la cláusula anterior, el incumplimiento por parte del Socio Conductor de
        alguna de las obligaciones establecidas en este documento producirá su resolución automática, quedando sin
        efecto en ese instante el derecho y autorización del Socio Conductor para utilizar la Aplicación. Producida la
        resolución automática, Gazelle le enviará una comunicación al Socio Conductor.</p>

    <h5>13. NATURALEZA DE LOS TÉRMINOS Y CONDICIONES:</h5>
    <p>El Socio Conductor declara y reconoce que el presente documento no podrá ser considerado como un contrato laboral
        ni una prestación de servicios independiente, sino que deberá ser considerado como los Términos y Condiciones de
        la Aplicación que le permitirá al Socio Conductor captar solicitudes de servicio requeridos por los Usuarios
        Clientes.</p>

    <h5>14. AUTONOMÍA E INDEPENDENCIA:</h5>
    <p>De acuerdo con los Términos y Condiciones, el Socio Conductor tendrá el derecho a utilizar una aplicación que le
        permita identificar requerimientos de servicio de los Usuarios Clientes. Así pues, luego que el Socio Conductor
        utilice la tecnología brindada por Gazelle y verifique las solicitudes de posibles clientes que requieran sus
        servicios, será plena decisión del Socio Conductor seleccionar los servicios que aceptará y la cantidad de
        servicios que brindará en cada oportunidad. En ese sentido, los servicios de transporte que el Socio Conductor
        preste serán brindados en forma independiente y autónoma, asumiendo los riesgos propios de la actividad.
        Asimismo, el servicio será ejecutado por cuenta, costo y riesgo del Socio Conductor.</p>

    <h5>15. DISPOSICIONES GENERALES:</h5>
    <p>15.1 Gazelle y el Socio Conductor dejan constancia que las secciones y disposiciones de los presentes Términos y
        Condiciones son separables y que la nulidad, ineficacia o invalidez de una o más de ellas no perjudicará a las
        restantes, las cuáles permanecerán como válidas, eficaces y vinculantes.</p>
    <p>15.2 Las acciones que generen responsabilidades administrativas, civiles o penales por parte de los socios
        conductores se encontrarán vigentes incluso luego de que el usuario deje de ser socio conductor.</p>
    <p>15.3 Estos Términos y Condiciones podrán ser modificados en cualquier momento por Gazelle mediante aviso por
        correo electrónico o a través de la Aplicación. El Socio Conductor tendrá derecho a terminar la presente
        relación jurídica si no está de acuerdo con las modificaciones. En caso de que el Socio Conductor continúe
        utilizando la Aplicación Gazelle Driver, después de haber recibido las modificaciones, se entenderá que el Socio
        Conductor ha aceptado las modificaciones.</p>
    <p>15.4 Los presentes Términos y Condiciones representan el acuerdo completo entre Gazelle y el Socio Conductor en
        relación con la Aplicación Gazelle Driver y reemplaza cualquier representación, conversación, compromiso,
        comunicación o publicidad anterior a la última modificación de los Términos y Condiciones en relación con la
        Aplicación. Esto también es aplicable en el momento que se publiquen las modificaciones a los Términos y
        Condiciones.</p>
    <p>15.5 En caso el Socio Conductor desee comunicarse con Gazelle, ya sea por consultas, reclamos y/o quejas, deberá
        enviar un correo electrónico a soporte@gazelledriver.com</p>

    <h5>16. POLÍTICAS DE PRIVACIDAD:</h5>
    <p>16.1 Responsable de sus Datos Personales. Gazelle, será el único responsable del tratamiento, uso, almacenamiento
        y divulgación de los datos personales del Usuario Conductor, conforme a lo establecido en las presentes
        políticas de privacidad.</p>
    <p>16.2 Los datos personales que recaba y almacena Gazelle como parte de su proceso de registro de los Socios
        Conductores serán:</p>
    <p>I. Nombre completo;</p>
    <p>II. Edad, la cual no podrá exceder de los 70 años al momento de registro, así como lugar y fecha de nacimiento;
    </p>
    <p>III. Copia del Documento Nacional de Identidad;</p>
    <p>IV. Número de teléfono celular y opcionalmente, teléfono fijo;</p>
    <p>V. Correo electrónico;</p>
    <p>VI. Imagen o foto del Socio Conductor (incluidas las extraídas del Facebook o Gmail, de ser el caso);</p>
    <p>VII. Modelo, marca, sistema operativo y características del teléfono móvil o celular o tableta del Socio
        Conductor;</p>
    <p>VIII. Dirección física, fecha y hora en la que el Socio Conductor acepta un servicio a través de la Aplicación;
    </p>
    <p>IX. Dirección física, fecha y hora en la que el Usuario Cliente requiere el vehículo del Socio Conductor para un
        servicio solicitado a través de la Aplicación, así como el destino al cual se dirige el trayecto y la ruta por
        la cual se dirige a dicho destino y la dirección física en la que el Socio Conductor culmina su servicio;</p>
    <p>X. Servicios aceptados y completados a través de la Aplicación, los tiempos de respuesta a solicitudes de
        transporte y los motivos de rechazo de servicios de transporte;</p>
    <p>XI. Ubicación del teléfono móvil, celular o tableta en tiempo real lo que puede revelar la ubicación del Usuario
        Cliente en tiempo real (Geolocalización), datos que serán válidos desde que se solicita el viaje hasta que el
        Socio Conductor finalice el mismo o el Usuario Cliente lo cancele por algún motivo. Adicionalmente el celular
        deberá encontrarse encendido y con el servicio de GPS activo para poder hacer uso del servicio;</p>
    <p>XII. Calificaciones, opiniones o comentarios que realicen los Usuarios Clientes o terceros respecto al Socio
        Conductor;</p>
    <p>XIII. Los datos técnicos de llamadas entre el Usuario Cliente y el Socio Conductor, siempre y cuando se realicen
        por la Aplicación;</p>
    <p>XIV. Los mensajes o quejas entre el Usuario Cliente y el Socio Conductor, siempre y cuando se realicen por la
        Aplicación;</p>
    <p>XV. Salvo lo descrito en el presente documento, Gazelle no recaba datos sensibles.</p>
    <p>XVI. Datos completos del automóvil o automóviles que conduce;</p>
    <p>XVII. SOAT vigente;</p>
    <p>XVIII. Información pública disponible existente sobre y/o los de antecedentes policiales, judiciales y penales de
        ser el caso y/o considerarlo conveniente GAZELLE.</p>
    <p>XIX. Dirección IP;</p>
    <p>XX. Formas de Obtener los Datos Personales. Gazelle obtiene datos personales directamente del Socio Conductor, de
        terceros, de Usuarios Clientes, de fuentes públicas o en forma automática a través de cookies, web beacons u
        otras funcionalidades que permiten ubicarlo e identificarlo.</p>
    <p>16.4 Uso de sus Datos Personales. Los datos personales con los que contará Gazelle se administrarán y se tratarán
        para las siguientes finalidades, las que son estrictamente necesarias para el cumplimiento de la relación
        jurídico-comercial que el Socio Conductor mantiene con Gazelle y para el uso de la Aplicación de acuerdo con sus
        funcionalidades. El Socio Conductor manifiesta aceptar y dar consentimiento libre, previo, expreso, inequívoco e
        informado para el tratamiento de sus datos personales a favor de Gazelle para los siguientes fines:</p>
    <p>I. El análisis y evaluación de sus características y permisos como Socio Conductor afiliado para conectarlo con
        Usuarios Clientes que cuenten con la Aplicación, así como para proceder a su validación en fuentes públicas y
        sitios oficiales;</p>
    <p>II. Para realizar procedimientos y gestiones de cobranza y el ejercicio de los derechos de Gazelle bajo los
        presentes Términos y Condiciones;</p>
    <p>III. Para monitorear el debido cumplimiento de la relación jurídica existente entre Gazelle y Socios Conductores;
    </p>
    <p>IV. Atender cualquier queja, pregunta o comentario del Socio Conductor;</p>
    <p>V. Para solicitarle y compartir sus opiniones, comentarios y calificaciones de Usuarios Clientes con otros Socios
        Conductores u otros Usuarios Clientes que tengan autorización de uso de la Aplicación;</p>
    <p>VI. Para compartir su ubicación e información con Usuarios Clientes que cuenten con la Aplicación y que éstos
        puedan evaluarlo y contactarlo;</p>
    <p>VII. Para compartir las evaluaciones, calificaciones, comentarios u opiniones que terceros hagan respecto del
        Socio Conductor con Usuarios Clientes que cuenten con la Aplicación;</p>
    <p>VIII. Fines estadísticos;</p>
    <p>IX. Mejorar la calidad de la Aplicación y los servicios de Gazelle;</p>
    <p>X. Investigar situaciones que estén relacionadas con actos que puedan estar asociadas a fraude, soborno,
        corrupción, lavado de activos, lavado de dinero, financiamiento de terrorismo, robo u otros similares.</p>
    <p>XI. Enviar notificaciones relacionadas con las modificaciones que se realicen a las presentes políticas de
        privacidad; y</p>
    <p>XII. Para el tratamiento de cualesquiera presuntos incidentes reportados como “emergencia” por el Usuario Cliente
        que haya ocurrido durante el servicio brindado por el Socio Conductor y en estricto rigor en los incidentes
        reportados como presunto: agresión física, agresión sexual, accidente vehicular, robo, hurto, objeto olvidado y
        delito contra la vida u otros delitos conexos. En dichos supuestos, el socio conductor acepta en compartir su
        Documento Nacional Identidad (y los datos contenidos en éste) y celular móvil en favor del usuario solicitante
        una vez se haya formalizado la denuncia respectiva ante las autoridades competentes.</p>
    <p>XIII. Otros relacionados con el servicio.</p>
    <p>16.5 En adición a lo anterior, al dar el Socio Conductor “clic” en el recuadro “he leído los términos de uso” y
        procede a “aceptar y continuar” , se entenderá que acepta de manera expresa que los datos de contacto que
        proporcione podrán ser utilizados para enviar notificaciones, avisos, propaganda o publicidad sobre productos de
        Gazelle o de terceros y para envío de información, revistas y noticias o de temas que Gazelle considere que
        pueden interesarle, así como noticias, comunicaciones o publicidad de Gazelle o sus empresas relacionadas.</p>
    <p>16.6 Los datos personales que se recaben, formarán parte de una base de datos que permanecerá vigente y éstos se
        conservarán mientras no se solicite la cancelación por el titular del dato, siendo que de presentarse dicho
        supuesto se procederá a su supresión de conformidad con la Ley de Protección de Datos Personales.</p>
    <p>El usuario acepta que se recopilen los datos personales ingresados en la aplicación Gazelle Driver y que estos
        puedan ser utilizados por terceros proveedores, sean nacionales, con la finalidad de mejorar la calidad de la
        aplicación y servicios de Gazelle, así como prevenir cualquier potencial situación de fraude que pueda
        ocasionarse dentro de la aplicación.</p>
    <p>16.7 Queda expresamente establecido que, con la aceptación de los presentes Términos y Condiciones, el Socio
        Conductor otorga su consentimiento para el uso de sus datos personales para las finalidades referidas, el cual
        es revocable en cualquier momento, para lo cual será necesario que envíe a Gazelle una comunicación a través de
        la siguiente dirección de correo electrónico soporte@gazelledriver.com </p>
    <p>16.8 De conformidad con lo establecido, Gazelle hará del conocimiento de dicho tercero los Términos y Condiciones
        de las presentes políticas de privacidad a efecto de salvaguardar los datos personales del Socio Conductor.
        Gazelle no requiere el consentimiento del Socio Conductor para hacer estas transferencias salvo que aquellas se
        hagan para una finalidad que sea diferente a las mencionadas anteriormente. </p>
    <p>16.9 Asimismo, sus datos de contacto, ubicación e información general (salvo información financiera), así como
        las opiniones, comentarios, evaluaciones y calificaciones que respecto al Socio Conductor hagan los Usuarios
        Clientes o usuarios de la Aplicación será compartida con otros Usuarios Clientes y usuarios que cuenten con la
        Aplicación. Si el Socio Conductor no desea que su información sea compartida con dichos Usuarios Clientes y
        usuarios que cuenten con la Aplicación entonces el Socio Conductor no podrá utilizar la Aplicación ya que es un
        requisito esencial para que el Socio Conductor pueda recibir solicitudes de servicio a través de la Aplicación.
    </p>
    <p>Por otro lado, sus opiniones, comentarios, evaluaciones y calificaciones respecto de Usuarios Clientes serán
        compartida con otros Socios Conductores o Usuarios Clientes de la Aplicación.</p>
    <p>16.10 Medidas de seguridad de los datos personales. Gazelle actuará con diligencia en la protección de Datos
        Personales de sus Socios Conductores, y adoptará todas y cada una de las medidas de seguridad administrativas,
        físicas y técnicas necesarias para salvaguardar los datos personales del Socio Conductor. </p>
    <p>16.11 Medios para ejercer los derechos de acceso, rectificación, oposición y cancelación y medios para limitar el
        uso y divulgación de sus datos personales. El Socio Conductor tiene derecho de acceder a sus datos personales
        que se encuentren en la base de datos de Gazelle y a los detalles del tratamiento de los mismos, incluyendo la
        individualización de la persona/s o agencia/s a las cuales dichos datos personales son regularmente
        transmitidos, así como a rectificarlos en caso de ser inexactos o incompletos, o cancelarlos cuando considere
        que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al
        tratamiento de los mismos para los fines específicos que no sean necesarios para la relación jurídica con
        Gazelle. </p>
    <p>En caso de que el Socio Conductor, como titular de sus datos personales, desee ejercer cualquiera de los derechos
        antes mencionados, será necesario que envíe a Gazelle una comunicación a través de la siguiente dirección de
        correo electrónico: soporte@gazelledriver.com </p>
    <p>Dicha solicitud deberá contener como mínimo la siguiente información: (a) nombre y domicilio, a efecto de dar
        respuesta a su solicitud en los plazos establecidos en la ley aplicable; (b) copia del Documento Nacional de
        Identidad, licencia para conducir o cualquier otro documento que acredite la identidad del Usuario Conductor o
        acredite la personalidad de su representante legal, y en caso que la solicitud se realice a través del
        representante legal, acompañar el documento que acredita sus facultades como representante; (c) descripción
        clara y precisa de los datos personales sobre los que se busca ejercer alguno de los derechos antes mencionados,
        así como la descripción clara y precisa de su solicitud; (d) fecha y firma del solicitante; y (e) cualquier otro
        elemento o documento que facilite la localización de dichos datos personales.</p>
    <p>Gazelle responderá cualquier solicitud completa en un plazo máximo de 10 días calendario o el máximo permitido
        por la ley, pudiendo ser ampliado por un plazo igual por razones debidamente justificadas. La respuesta de
        Gazelle indicará si la solicitud de acceso, rectificación, cancelación u oposición es procedente y, en caso que
        así lo sea, Gazelle hará efectiva la determinación de manera automática. Gazelle proporcionará copias
        electrónicas de su información personal en caso de que el Socio Conductor ejerza su derecho de acceso.</p>
    <p>16.12 ¿Qué opciones tiene para limitar el uso y divulgación de sus datos? El uso de sus datos personales para
        aquellas finalidades que no son estrictamente necesarias para el cumplimiento de su relación con Gazelle y para
        limitar la manera en la que estime conveniente su uso y divulgación. El Socio Conductor podrá revocar o limitar
        su consentimiento para la utilización de sus datos personales para finalidades que no son estrictamente
        necesarias para el cumplimiento de la relación con Gazelle o para que compartamos sus datos personales con
        personas o finalidades para las cuales requerimos su consentimiento a través de un correo electrónico a
        soporte@gazelledriver.com o mediante la opción “contáctanos” en la Aplicación.</p>
    <p>Particularmente, según lo establecido en la normatividad vigente, el Usuario Conductor tiene derecho a dejar de
        recibir mensajes de publicidad en su correo electrónico, teléfono o por cualquier otro medio a través de los
        procedimientos que hemos implementado. El Socio Conductor puede contactar a Gazelle en la forma que se indica
        anteriormente para estos efectos.</p>
    <p>16.13 Redes Sociales. Gazelle podrá contar con un perfil, ya sea de la empresa o de sus productos, en las
        principales redes sociales, tales como Facebook, instagram. (tal como se ha mencionado en los Términos y
        Condiciones, las “Redes Sociales”).</p>
    <p>Las Redes Sociales, servirán única y exclusivamente como un canal de comunicación inmediato con los seguidores de
        Gazelle o sus productos, así como un medio electrónico de información y marketing de nuevos productos ofertas y
        promociones que sean realizadas por Gazelle exclusivamente en su perfil y que será accesible a través del mismo
        a sus seguidores. Gazelle únicamente podrá conocer los datos personales que así lo permitan las Redes Sociales y
        en ningún caso extraerá los datos personales que pueda conocer de sus seguidores sin el consentimiento previo.
    </p>
    <p>Por otro lado, la Aplicación puede contener enlaces a Redes Sociales a través de las cuales el Socio Conductor
        puede compartir información personal.</p>
    <p>Gazelle hace del conocimiento del Socio Conductor que tanto las Redes Sociales serán los titulares de las bases
        de datos que a éstos les corresponda, por lo que Gazelle lo invita a leer las políticas de privacidad y
        condiciones de uso de las mismas.</p>
    <p>Algunos Usuarios Clientes o terceros pueden subir información del Socio Conductor a las Redes Sociales,
        incluyendo comentarios o calificaciones de sus servicios. Gazelle no se hace responsable por esos comentarios o
        calificaciones.</p>
    <p>16.14 Modificaciones a las políticas de privacidad. En relación con lo anterior Gazelle se reserva el derecho a
        modificar los Términos y Condiciones de estas políticas de privacidad, acción que será publicada en la
        Aplicación o a través del sitio web de Gazelle: https://gazelledriver.com/ , al que tendrá acceso cualquier
        persona que se encuentre interesada. De cualquier forma, Gazelle recomienda al Socio Conductor que revise estas
        políticas de privacidad cada vez que haga uso de la Aplicación, a efecto de saber los cambios, modificaciones o
        actualizaciones que se realicen al mismo.</p>

    <h5>EL SUSCRITO, OTORGA LA ACEPTACIÓN Y CONSENTIMIENTO A LOS TÉRMINOS Y CONDICIONES, INCLUYENDO EL AVISO DE
        PRIVACIDAD Y ENVIO DE PROMOCIONES, PUBLICIDAD Y/O INFORMACION AL PRESIONAR “CLIC” EN EL BOTÓN ACEPTAR DESDE LA
        APLICACIÓN GAZELLE DRIVER - CONDUCTOR.</h5>

    <div class="spacer"></div>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>