import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ServiceComponent } from './components/service/service.component';
import { CustomersComponent } from './components/customers/customers.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { CustomerTermsComponent } from './components/customer-terms/customer-terms.component';
import { DriverTermsComponent } from './components/driver-terms/driver-terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { SerendipiaComponent } from './catalogs/serendipia/serendipia.component';
import { PukllayComponent } from './catalogs/pukllay/pukllay.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ServiceComponent,
    CustomersComponent,
    DriversComponent,
    CustomerTermsComponent,
    DriverTermsComponent,
    PrivacyComponent,
    SerendipiaComponent,
    PukllayComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
