<style>
    h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/ga-logo-500.png" alt="Gazelle App" style="width: 150px" />
            </div>
        </a>
    </div>
    <h3>Nuestros clientes</h3>
    <p class="mb-4">
        Nuestros clientes podrán viajar seguros, nuestro servicio esta regulado bajo las normas de seguridad
        dispuestas por el gobierno y cada viaje es monitoreado de inicio a fin para brindar un servicio confiable y de
        calidad.
    </p>
    <div class="spacer"></div>
    <div class="time-counter form-row other-style">
        <div class="hours count col">
            <a routerLink="/customer/policy" class="btn btn-secondary" style="margin-bottom:10px">Ver términos del
                cliente</a>
        </div>
    </div>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>