import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PukllayComponent } from './catalogs/pukllay/pukllay.component';
import { SerendipiaComponent } from './catalogs/serendipia/serendipia.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CustomerTermsComponent } from './components/customer-terms/customer-terms.component';
import { CustomersComponent } from './components/customers/customers.component';
import { DriverTermsComponent } from './components/driver-terms/driver-terms.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ServiceComponent } from './components/service/service.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'customer/policy', component: CustomerTermsComponent },
  { path: 'drivers', component: DriversComponent },
  { path: 'driver/policy', component: DriverTermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'b/serendipia', component: SerendipiaComponent },
  { path: 'b/pukllay', component: PukllayComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
