<style>
    h3 {
        color: #000000;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .form-box-lg {
        display: inline-block;
        width: 100%;
        /* max-width: 500px; */
        text-align: left;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        background-color: #fafafa;
        padding: 40px 30px;
        color: #000000 !important;
    }

    .form-box-lg h3 {
        color: #222222 !important;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
    }

    .form-box-lg h5 {
        text-align: left !important;
        color: #444444 !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin: 20px 0 12px;
    }

    .form-box-lg p {
        text-align: left !important;
        color: #000000 !important;
        font-size: 14px;
        margin: 0 0 12px 0;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box-lg">

    <h3>TÉRMINOS Y CONDICIONES ASIGNABLE A USUARIOS CLIENTES PARA EL USO DE LA APLICACIÓN “GAZELLE APP - CLIENTE”</h3>

    <p class="mb-3">
        Actualizado el 26 de agosto de 2021.
    </p>
    <p>
        AVISO PARA LOS USUARIOS CLIENTES: El Usuario Cliente acepta todos los Términos y Condiciones establecidos en el
        presente documento, antes de utilizar la Aplicación Gazelle App – cliente (en adelante denominado como
        Aplicación
        Gazelle cliente), así como cualquier actualización futura de dicha aplicación y/o de las presentes políticas. De
        igual modo, el Usuario Cliente acepta las políticas complementarias y/o específicas vigentes que pudieren
        existir
        para servicios específicos, así como las actualizaciones de las mismas, en el momento que ello ocurriere.
    </p>
    <p>
        En caso el Usuario Cliente no esté de acuerdo con los Términos y Condiciones siguientes, deberá abstenerse de
        utilizar esta aplicación y sus actualizaciones, toda vez que la utilización de la Aplicación Gazelle cliente
        implica
        manifestación de conformidad del Usuario con todos los Términos y Condiciones. En caso el Usuario Cliente
        continúe
        utilizando la Aplicación Gazelle cliente, se entenderá que las ha aceptado completamente.
    </p>
    <p>
        DECLARACIONES DE LOS USUARIOS CLIENTES: El Usuario Cliente declara y afirma que tiene por lo menos 18 años de
        edad,
        o es un menor de edad con capacidad jurídica, o posee consentimiento legal paterno o de su tutor, de acuerdo a
        lo
        establecido por el Código Civil Peruano para obligarse con los presentes Términos y Condiciones, además de
        acatarlos
        y cumplirlos.
    </p>

    <p>
        Al descargar y/o usar la Aplicación Gazelle cliente, usted manifiesta y garantiza lo siguiente:
    </p>
    <p>I. Que tiene el derecho, autoridad y capacidad para cumplir con todos los Términos y Condiciones.
    </p>
    <p>II. Que está en la posibilidad de pagar por el servicio brindado por los Socios Conductores.</p>
    <p>III. Que la información que proporcione a Gazelle será veraz y precisa.</p>
    <p>IV. Que la utilización de la Aplicación Gazelle cliente es a título personal, no siendo posible que
        sea utilizado por un tercero, diferente a usted.</p>
    <p>V. Que no utilizara la Aplicación Gazelle cliente para el transporte de bienes valiosos (a)
        Materiales y Residuos Peligrosos; (b) Psicotrópicos y Estupefacientes; (c) Armas de Fuego y Explosivos; (d)
        Animales; (e) Dinero o Títulos de Crédito al Portador o Negociables; (f) Cualquier producto que no cuente con el
        permiso específico o bien, lo restrinja alguna ley; (g) Artículos que rebasen las dimensiones de carga del
        vehículo (sofá, escaleras, mesas, etc.)</p>
    <p>VI. Que tiene pleno conocimiento y acepta que Gazelle no es una empresa que brinde o realice
        servicios de taxi.</p>
    <p>VII. El Usuario Cliente no utilizará el Servicio “Envío” para transportarse de un lugar a otro, el
        uso de este servicio es exclusivo para encomiendas/envíos.</p>
    <p>VIII. Que tiene pleno conocimiento que los usuarios conductores no son empleados ni tienen una
        relación de subordinación o laboral con Gazelle.</p>

    <h5>1. DEFINICIONES:</h5>
    <p>Para los efectos de los presentes Términos y Condiciones, se deberán tener en cuenta las siguientes
        definiciones, sin importar si se utilizan en la forma singular o plural:</p>
    <p>1.1 “Aplicación” o “Aplicación Gazelle cliente” significa la aplicación denominada “Gazelle” para
        Usuarios Clientes, que es un programa informático que permite que su teléfono celular o móvil, tableta y/o
        computador personal realice las funciones o tareas que se describen en la sección 3 de estos Términos y
        Condiciones.</p>
    <p>1.2 “Aplicación Gazelle Driver - conductor” significa la aplicación denominada “Gazelle Driver”, que
        es un programa informático que le permite a los Socios Conductores, por medio de sus teléfonos celulares o
        móviles o tabletas aceptar solicitudes de servicios de transporte requeridos por los Usuarios Clientes.</p>
    <p>1.3 “Contraseña” significa es una forma de autentificación que utiliza información secreta para para
        acceder a la Aplicación.</p>
    <p>1.4 “Usuario Cliente” significa el cliente que ha completado el proceso de registro, aceptado los
        Términos y Condiciones detallados a continuación y ha sido autorizado por Gazelle para el uso de la Aplicación,
        (servicio de viajar o servicio envío).</p>
    <p>1.5 “Términos y Condiciones” significa el conjunto de disposiciones y condiciones, contenidas en el
        presente documento, a las que se obligan a cumplir los Usuarios Clientes, para efectos de utilizar la
        Aplicación.</p>
    <p>1.6 “Socio Conductor” significa aquel socio que ha completado satisfactoriamente el proceso de
        registro, que proporciona a título personal el servicio de transporte de pasajeros y/o envíos que se ha afiliado
        a Gazelle con el fin de poder utilizar la “Aplicación Gazelle Driver” que le permite aceptar solicitudes de
        servicios realizadas por Usuarios Clientes.</p>

    <h5>2. AUTORIZACIÓN DE USO DE LA APLICACIÓN, REQUERIMIENTOS TÉCNICOS Y RESTRICCIONES DE USO:</h5>
    <p>2.1 Durante la vigencia de los presentes Términos y Condiciones, el Usuario Cliente tendrá el derecho no
        exclusivo, gratuito, revocable e intransferible de utilizar la Aplicación exclusivamente para las funciones que
        se establecen más adelante en la Sección 3.</p>
    <p>2.2 El Usuario Cliente recibirá una Contraseña vía SMS al número de celular registrado en la Aplicación de modo
        que será el único autorizado para acceder a la Aplicación mediante tal contraseña. El Usuario Cliente será el
        único responsable de la conservación y confidencialidad de su Contraseña y se compromete a no comunicarla bajo
        ninguna circunstancia a ningún otro usuario y/o tercero, y a no utilizar la cuenta, el nombre o la contraseña de
        ninguna otra persona. Queda expresamente establecido que el uso de la contraseña y/o de la Aplicación
        constituyen su aceptación incondicional de los presentes Términos y Condiciones.</p>
    <p>2.3 El Usuario Cliente será responsable de contar con un teléfono celular, móvil o tableta que cumpla con las
        características y requerimientos técnicos para acceder y utilizar la Aplicación, incluyendo la conexión a
        Internet. Las características y requerimientos técnicos referidos serán notificados al Usuario Cliente por
        Gazelle antes de la descarga de la Aplicación o cualquiera de sus actualizaciones. El Usuario Cliente asumirá y
        será responsable por el costo de su servicio telefónico y del Internet, y deberá consultar con las empresas de
        telecomunicaciones respectivas las tarifas por el uso de planes de voz y/o de datos. </p>
    <p>De otro lado, Gazelle se reserva el derecho de validar la versión del sistema operativo que utiliza el Usuario
        Cliente para acceder a la Aplicación, desde cualquier plataforma, a fin de informarle al Usuario Cliente que
        requiere instalar o actualizar determinado programa para acceder o mejorar su acceso a la Aplicación.</p>
    <p>2.4 El Usuario Cliente se obliga a no modificar, reproducir, copiar, realizar ingeniería inversa, revertir la
        ingeniería, rediseñar, descompilar, adaptar, traducir, preparar trabajos derivados de la Aplicación o usar la
        Aplicación para desarrollar cualquier software u otros materiales basados en el mismo. Asimismo, se obliga a
        utilizar la Aplicación únicamente en la forma permitida en estos Términos y Condiciones. Gazelle declara que la
        Aplicación se encuentra protegida por la legislación vigente en materia de Derechos de Autor, la misma que el
        Usuario Solicitante declara y afirma conocer y se compromete a cumplir a cabalidad. </p>
    <p>2.5 El Usuario Cliente acepta y reconoce que Gazelle podrá realizar acciones legales para requerir y asegurar el
        cumplimiento de todas las disposiciones establecidas en los presentes Términos y Condiciones, así como
        solicitarle el resarcimiento por daños y perjuicios ocasionados por incumplimiento de sus obligaciones legales
        y/o derivada del presente documento (ocasionado por fraude, uso indebido del perfil, entre otros), incluyendo
        tal concepto la indemnización por daño emergente y lucro cesante.</p>
    <p>El Usuario Cliente se obliga a respetar, velar y proteger los datos personales de los Socios Conductores que
        acepten los servicios, por lo que se compromete a utilizar dicha información única y exclusivamente para
        contactarlos con la finalidad de concretar el servicio de transporte.</p>
    <p>2.6 Toda la información que facilite el Usuario Conductor a Gazelle deberá ser veraz. A estos efectos, el Usuario
        Cliente garantiza la autenticidad de todos aquellos datos que comunique como consecuencia del llenado y
        posterior envío a Gazelle de los formularios necesarios para la suscripción del servicio. De igual forma, el
        Usuario Cliente será responsable de mantener toda la información facilitada a Gazelle a través de la Aplicación,
        permanentemente actualizada de forma que responda, en cada momento, a su situación real y actual. Asimismo, el
        Usuario Cliente será el único responsable de las manifestaciones falsas o inexactas que realice y de los
        perjuicios que cause a Gazelle y/o a terceros por la información que facilite.</p>
    <p>2.7 El Usuario Cliente reconoce expresamente que es el único y exclusivo responsable del uso que le dé a la
        Aplicación, así como de cualquier acción que tenga lugar mediante o a raíz de dicho uso, por lo que no cabe
        imputación alguna de responsabilidad contra Gazelle.</p>
    <p>2.8 En caso de que se asocien las cuentas de los Usuarios Clientes de la Aplicación a cuentas de Facebook o
        Gmail, el Usuario Solicitante autoriza expresamente el uso de su foto de perfil de Facebook (también denominado
        para estos efectos, “Avatar”) para un mejor reconocimiento del mismo por parte del Socio Conductor.</p>
    <p>2.9 El Usuario Cliente reconoce el derecho de Gazelle a proceder inmediatamente y sin notificación previa a
        retirarlo de la Aplicación y/o a bloquear su acceso a éste, en el caso de que el uso que le dé a la Aplicación
        sea inadecuado, ilícito, abusivo o viole los presentes Términos y Condiciones, sus documentos conexos y/o las
        actualizaciones de estos o en general los derechos de terceros, las leyes y los reglamentos vigentes, en estos
        casos, Gazelle se reserva el derecho de realizar las acciones legales correspondientes.</p>
    <p>2.10 No se podrán registrar desde un mismo número telefónico más de un usuario cliente.</p>
    <p>2.11 Durante la emergencia sanitaria declarada por el gobierno peruano a propósito de la pandemia mundial
        denominada COVID-19, el Usuario Cliente se compromete a respetar las recomendaciones en materia de seguridad
        sanitaria emitidas por el Ministerio de Salud. Adicionalmente, el Usuario Cliente declara y se obliga a lo
        siguiente:</p>
    <p>(a) Usar el aplicativo siempre que NO hubiera sido diagnosticado con COVID-19, tuviera algún síntoma
        característico de COVID-19 o bien se encuentre a la espera de la entrega de sus resultados por parte de la
        entidad de salud autorizada.</p>
    <p>(b) Usar mascarilla durante el uso del aplicativo</p>
    <p>(c) Permitir el uso de tres (03) pasajeros como máximo.</p>
    <p>(d) Proveer ventilación natural al vehículo. Esto es, NO usar aire acondicionado sino por el contrario, mantener
        las ventanas abiertas en todo momento.</p>
    <p>2.13 En caso de que el Usuario cliente viole la legislación o infrinja los derechos de terceros y/o exista duda
        razonable de ello, Gazelle tiene derecho a facilitar, previa solicitud de cualquier autoridad legítima
        (tribunales, autoridades administrativas, fiscales y/o las fuerzas policiales), cualquier información que
        permita o facilite la identificación del infractor. En el supuesto que el afectado sea un Socio Conductor,
        Gazelle facilitará los datos a su alcance con el fin de que el afectado pueda iniciar las acciones legales
        correspondientes, condición que es aceptada por el Usuario cliente al aceptar estos términos y condiciones.</p>

    <h5>3. FUNCIONES DE LA APLICACIÓN:</h5>
    <p>3.1 Por medio de la Aplicación, el Usuario Cliente podrá solicitar servicios de traslado a los Socios Conductores
        afiliados a la Aplicación móvil de Gazelle Driver, así como conocer el nombre, ubicación y otra información
        correspondiente del Socio Conductor respectivo. El Usuario Cliente reconoce que la información que reciba de los
        Socios Conductores se trata de Datos Personales, por lo que a su vez se constituye como único responsable del
        manejo de la misma y se compromete a utilizarla exclusivamente a efectos de solicitar el servicio del Socio
        Conductor y durante el desarrollo del mismo. </p>
    <p>3.2 El Usuario Cliente, podrá elegir grabar audio del dispositivo durante el viaje con la aplicación, así como
        utilizar la grabación para enviar un reporte al equipo de soporte de Gazelle si algo le hace sentir incómodo en
        el camino. El audio es un recurso adicional para que, en caso de tener reporte de un incidente, podamos
        comprender lo ocurrido y tomar las medidas pertinentes. ¿Cómo funciona esta herramienta? La opción para comenzar
        a grabar el audio se encuentra en las opciones del viaje en curso. Al pulsar “Grabar audio del dispositivo”,
        haber aceptado los términos de uso y dar el permiso de grabar audio del dispositivo, al presionar clic en el
        botón “Iniciar grabación”, se ejecuta un proceso en segundo plano y empezará a grabar el audio, este será
        detenido de forma manual, en caso de haber cerrado y sin detener la grabación no será posible grabar el audio,
        una vez que se haya detenido la grabación de forma correcta, será enviado al equipo de soporte de Gazelle, solo
        se hará uso si el Usuario Cliente lo autoriza.</p>
    <p>3.3 El usuario Cliente puede subir una foto de perfil a la aplicación, para lo cual esta se solicitará el permiso
        de acceso a los archivos del dispositivo. Esta opción no es obligatoria siempre y cuando el Usuario Cliente no
        decida agregar y/o modificar la foto de perfil de su cuenta en Gazelle App, pero si se recomienda para una mejor
        identificación del usuario y posterior ayuda en soporte.</p>
    <p>3.4 Los Socios Conductores son independientes y libres de aceptar o rechazar las solicitudes de servicios que
        envíe el Usuario Cliente, incluso si los Socios Conductores aparecen como disponibles en la Aplicación. Al
        respecto, por medio de los presentes Términos y Condiciones, queda establecido que ni Gazelle ni su Aplicación
        Gazelle son una empresa de taxi o “radio taxi” ni presta un servicio de transporte de pasajeros, ni posee una
        flota de vehículos para transportar pasajeros, ni es un sitio web de taxi. Asimismo, Gazelle no tiene una
        relación laboral, de subordinación o exclusividad con los Socios Conductores. Gazelle únicamente autoriza el uso
        de la Aplicación Gazelle cliente a efectos que el Usuario Cliente pueda conectarse a través de la misma con los
        Socios Conductores que utilicen la Aplicación Gazelle Driver para solicitar sus servicios de traslado.</p>
    <p>3.5 Gazelle se reserva el derecho de, a su exclusivo juicio, bloquear temporal o definitivamente el acceso de
        Usuarios Clientes o remover en forma parcial o total, toda información, comunicación o material, incluyendo
        opiniones, comentarios o calificaciones que el Usuario Cliente u otros Usuarios Cliente, Socios Conductores o
        usuarios de la Aplicación de Gazelle cliente o la Aplicación de Gazelle Driver publiquen a través de las Redes
        Sociales (Facebook, Instagram, página web de Gazelle, entre otros similares) u otro medio de comunicación o
        espacio generado por Gazelle:</p>
    <p>(a) Que esté protegido por derechos de propiedad intelectual, protegidas por el secreto comercial o marca
        comercial, o de otra manera impliquen los derechos de propiedad de terceros, incluidos los derechos de
        privacidad y publicidad, a menos que usted sea el propietario de dichos derechos o tenga permiso o una licencia
        de su legítimo propietario para publicar el material.</p>
    <p>(b) Que acose, degrade, intimide o sea odioso hacia un individuo o grupo de individuos sobre la base de la
        religión, el género, la orientación sexual, la raza, la etnia, la edad o la discapacidad.</p>
    <p>(c) Que sea dañino, abusivo, ilegal, amenazante, acosador, difamatorio, pornográfico, calumnioso, invasivo de la
        privacidad, que dañe o pueda dañar a menores de cualquier manera.</p>
    <p>(d) Que discrimine por razón de raza, etnia, religión, sexo, origen nacional, edad, discapacidad u otro motivo
        que afecte la dignidad de las personas o terceros.</p>
    <p>(e) Que incluya información personal o de identificación de otra persona sin el consentimiento expreso de esa
        persona.</p>
    <p>(f) Que imite a otra persona o entidad, incluyendo, pero no limitado a, un empleado de Gazelle, o falsa
        declaración o distorsione su afiliación con una persona o entidad.</p>
    <p>(g) Que constituya o contenga “esquemas piramidales”, “marketing de afiliación”, “código de referencia a enlace”,
        “correo basura”, “spam”, “cartas en cadena”, o anuncios publicitarios no solicitados de carácter comercial.</p>
    <p>(h) Que haga referencia o incluya enlaces a servicios comerciales o sitios Web de terceros.</p>
    <p>(i) Que publicite servicios ilícitos o la venta de cualquier artículo cuya venta está prohibida o restringida por
        la ley aplicable.</p>
    <p>(j) Que contenga virus de software o cualquier otro código de computadora, archivos o programas diseñados para
        interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware o equipo de
        telecomunicaciones.</p>
    <p>(k) Que interrumpa el flujo normal del diálogo con una cantidad excesiva de mensajes (ataque masivo) al Servicio,
        o que afecte en forma negativa la capacidad de otros usuarios para utilizar el Servicio.</p>
    <p>3.6 El Usuario Cliente podrá también conocer, la calificación consolidada de los Socios Conductores, en base a
        las calificaciones brindadas por otros Usuarios Clientes. </p>
    <p>3.7 Para que una persona natural se convierta en Socio Conductor, deberá pasar por una evaluación previa, tras lo
        cual podrá utilizar la Aplicación y, en consecuencia, puede aceptar o rechazar de modo libre y autónomo una
        solicitud de servicio, no encontrándose obligado y siendo única y exclusivamente responsable por la aceptación o
        rechazo de una solicitud de servicio, así como por el servicio que brinde y todo lo que pueda ocurrir en éste.
        Gazelle no tiene ninguna relación o vínculo laboral o de subordinación con el Socio Conductor registrado en la
        Aplicación Gazelle Driver. De este modo, cualquier responsabilidad legal (civil, penal o administrativa) por
        actos realizados, información no veraz y/o incumplimientos normativos será responsabilidad plena de dicho Socio
        Conductor, ya sea frente al Usuario Cliente como frente a terceros. </p>
    <p>En caso los Usuarios Clientes tengan algún inconveniente con los servicios prestados por los Socios Conductores,
        podrán reportar dichos inconvenientes a Gazelle, a través del aplicativo “Escribir a soporte” y escribir el tipo
        de caso con el que necesiten asistencia. De igual manera, podrán mantener contacto a través del correo
        contacto@gazelledriver.com Gazelle procurará sus mejores esfuerzos para que entre los Usuarios Clientes y los
        Socios Conductores se puedan resolver los inconvenientes.</p>
    <p>3.8 El Usuario Cliente es libre en todo momento de enviar solicitudes de servicio a través de la Aplicación. La
        relación entre el Socio Conductor y el Usuario Cliente es autónoma e independiente, no siendo responsable
        Gazelle, ni directa ni indirectamente, por lo que pudiese ocurrir en la referida relación jurídica.</p>
    <p>3.9 Por medio de los presentes Términos y Condiciones queda establecido que Gazelle no es responsable de las
        calificaciones que otorgue el Socio Conductor, otros socios conductores o los Usuarios Clientes con relación a
        los servicios. Asimismo, el Usuario Cliente reconoce que asume el riesgo respecto de la honradez, solvencia y/o
        cumplimiento de los Socios Conductores que acepten sus servicios, no siendo posible imputar a Gazelle
        responsabilidad alguna, directo o indirecta, al respecto. Gazelle no será responsable por actos o hechos de los
        Socios Conductores afiliados ni garantiza su honradez, solvencia, moralidad o comportamiento.</p>
    <p>3.10 Si el Usuario Cliente desea obtener mayor información acerca de un Socio Conductor en particular, deberá
        consultarlo con Gazelle, quien evaluará dicho requerimiento de modo tal que no incumpla la legislación vigente
        para entregar dicha información y, de ser el caso, solicitará el traslado de aquella información a su titular.
    </p>

    <h5>4. USO DE LA APLICACIÓN:</h5>
    <p>4.1 El Usuario Cliente será responsable del costo de su servicio telefónico y de Internet, y deberá consultar con
        las empresas de telecomunicaciones respectivas las tarifas por el uso de planes de voz y/o de datos.</p>
    <p>4.2 El Usuario Cliente comprende y acepta que GAZELLE se reservará el derecho de cobrar un monto correspondiente
        a “cuota de servicio”, la cual se adicionará a la tarifa del viaje, en ese sentido, el Usuario Cliente el monto
        total que incluye la tarifa de viaje más la “cuota de servicio” antes de aceptar el viaje. La “cuota de
        servicio” es un monto que pagará únicamente el Usuario Cliente.</p>
    <p>El concepto correspondiente a “cuota de servicio” es un concepto asociado a los costos operacionales para el
        desarrollo y mantenimiento de la tecnología necesaria para el buen funcionamiento de la Aplicación. Este cobro
        no modifica los términos del Servicio que brinda GAZELLE a los socios conductores.</p>

    <h5>5. MODALIDADES DE PAGO DEL TRASLADO DE USUARIOS CLIENTES:</h5>
    <p>5.1 La Aplicación Gazelle brinda las mayores facilidades con el fin de concretar el pago de los servicios del
        Usuario Cliente de una manera eficiente.</p>
    <p>5.2 El pago se puede realizar mediante dos modalidades:</p>
    <p>a) A través de los Socios Conductores mediante el uso de efectivo, y</p>
    <p>b) Mediante el uso de billeteras digitales. El precio de las tarifas es mostrado en soles antes de solicitar el
        servicio, siempre y cuando el Usuario Cliente señale el lugar de destino. </p>
    <p>La modalidad de pago podrá ser seleccionada al momento de indicar el destino del traslado en la Aplicación
        Gazelle, antes de solicitar el mismo. </p>
    <p>Cabe señalar que, para todos los casos, el Usuario Cliente deberá incluir el destino específico del servicio. En
        este sentido, el Usuario Cliente declara y acepta lo siguiente:</p>
    <p>(a) En caso el área de soporte o Usuario Cliente cancele el servicio, el sistema procederá a reembolsar el monto
        “pre-cargado” al Usuario Cliente automáticamente.</p>
    <p> (b) En caso de cambio de destino durante el servicio procederá lo siguiente: (1) Si la nueva tarifa es mayor, el
        monto adicional será cobrado al Usuario Cliente al final del servicio, o (2) Si la nueva tarifa es menor, se
        realizará el reembolso de todo el monto al Usuario Cliente y posteriormente se cobrará el monto final y correcto
        de la tarifa.</p>
    <p>(c) Los cargos realizados pueden incluir otras tarifas, y/o cargos adicionales, incluidas las cuotas de servicio,
        recargo por servicio (tales como, recargos de aeropuerto y playas) o cualquier otra tarifa a ser aplicada de
        acuerdo a disposiciones gubernamentales.</p>
    <p>(d) Es posible que los cambios en la tarifa de viaje sean bajo un marco de acuerdo verbal entre el Usuario
        Cliente y el Socio Conductor, pero siempre siguiendo los lineamientos dispuestos en este documento.</p>
    <p>Gazelle podrá bloquear de forma temporal o permanente cuando se tengan operaciones fraudulentas. En estos casos,
        Gazelle podrá permitir que el Usuario Cliente siga contratando el servicio únicamente mediante el pago en
        efectivo a través de los Socios Conductores.</p>
    <p>De registrarse un nuevo instrumento de pago en la Aplicación, Gazelle queda autorizado a emplearlo para cobrar la
        deuda pendiente. Una vez realizado el pago, que le será comunicado al Usuario Cliente a través de la Aplicación
        y/o vía correo electrónico, el bloqueo mencionado en el párrafo precedente se levantará, enviándosele el recibo
        correspondiente. Las tarifas cobradas no serán reembolsables.</p>
    <p>5.4 En determinadas circunstancias se podrá variar la tarifa de un traslado por circunstancias tales como
        períodos de tiempo de alta demanda, así como a determinadas zonas durante períodos específicos de tiempo
        (Tarifas Dinámicas). Esta variación estará reflejada en la tarifa que el Usuario Cliente conocerá de manera
        previa a realizar su solicitud de traslado.</p>
    <p>5.5 Gazelle podrá restringir el uso de promociones y descuentos cuando los usuarios hagan un uso inadecuado,
        ilícito o abusivo de las mismas.</p>
    <p>Las Promociones y Descuentos:</p>
    <p>(i) Deben utilizarse de acuerdo con las políticas específicas establecidas, (ii) No podrán ser utilizadas más
        allá del plazo establecido; (iii) Gazelle podrá revocarlas en cualquier momento, en el marco de la legislación
        vigente, sin responsabilidad legal para Gazelle; (iv) De ser promociones reservadas no podrán ser puestas a
        disposición abierta del público en general por parte de los beneficiarios.</p>

    <h5>6. PROHIBICIÓN DE DISTRIBUCIÓN Y TRANSFERENCIA:</h5>
    <p>6.1 El Usuario cliente no podrá distribuir, arrendar, otorgar licencias, ceder ni transferir sus derechos de uso
        de la Aplicación, ni autorizar su copia total o parcial en el equipo de otro usuario. Asimismo, bajo ningún
        motivo, pondrá la Aplicación a disposición de otras personas, ni en un servidor host, oficina de servicio o
        similares, ni a través de Internet o de cualquier otro modo.</p>

    <h5>7. DERECHOS DE PROPIEDAD INTELECTUAL Y OBLIGACIONES DE CONTENIDOS</h5>
    <p>7.1 Gazelle y sus proveedores y filiales son licenciatarios de la propiedad intelectual de la Aplicación,
        incluyendo los derechos de autor sobre el código fuente y el código objeto de la Aplicación y los derechos sobre
        todas las marcas, logos, slogans, diseños, imágenes, videos, información, música, sonidos, nombres comerciales y
        otra Propiedad Intelectual e Industrial que forme parte del contenido de la Aplicación. En consecuencia, el
        Usuario Cliente se encuentra prohibido y se compromete expresamente a no copiar, reproducir, modificar, alterar,
        distribuir, suprimir o de cualquier forma usar dicha propiedad intelectual en forma distinta a la establecida en
        estos Términos y Condiciones.</p>
    <p>7.2 Los presentes Términos y Condiciones regulan la autorización de uso de la Aplicación por parte de los Socios
        Conductores, mas no de venta, por lo que la autorización que recibe el Usuario Cliente para el uso de la
        Aplicación no implica que este reciba la propiedad de la Aplicación o de sus derechos. En consecuencia, el
        Usuario Cliente se encuentra impedido de instalar, copiar y/o utilizar la Aplicación o Página Web de forma
        contraria a la establecida en los presentes Términos y Condiciones. Gazelle, sus proveedores y filiales se
        reservan todos los derechos otorgados expresamente en virtud de estos Términos y Condiciones.</p>
    <p>7.3 El Usuario Cliente no ha abonado contraprestación alguna por el uso de los derechos de Propiedad Intelectual
        de Gazelle, y ninguna de las disposiciones de los presentes Términos y Condiciones dará derecho al Usuario
        Cliente sobre los derechos de Propiedad Intelectual de Gazelle. El Usuario Cliente no utilizará, ni solicitará
        el registro, dentro del territorio de las marcas, nombres comerciales, logotipos, denominaciones sociales,
        nombres de dominio, signos distintivos u otras designaciones ni intentará registrar las marcas, nombres
        comerciales, logotipos, denominaciones sociales, nombres de dominio, signos distintivos u otras designaciones
        que pudieran causar confusión con los derechos de Propiedad Intelectual de Gazelle.</p>
    <p>7.4 El Usuario Cliente reconoce que Gazelle es licenciataria de todos los derechos de Propiedad Intelectual de
        Gazelle y demás derechos intelectuales de Gazelle, por lo que acuerda no reclamar en ningún momento durante la
        vigencia de estos Términos y Condiciones ni con posteriores a ello ningún derecho ni hacer que afecte la validez
        de cualquiera de los derechos de Propiedad Intelectual de Gazelle (incluyendo, sin que implique limitación
        alguna, cualquier acto o asistencia que pudiera violar o causar la violación de cualquiera de los derechos
        patrimoniales de Gazelle). El Usuario Cliente acuerda adherirse a las políticas e instrucciones de Gazelle
        respecto del uso de los derechos de Propiedad Intelectual de Gazelle, conforme pudieran ser publicados o
        comunicados en forma periódica.</p>

    <h5>8. ACTUALIZACIONES DE LA APLICACIÓN:</h5>
    <p>8.1 Usted es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para
        acceder y utilizar los servicios, así como cualquier actualización que GAZELLE realice a la aplicación. Queda
        establecido que la descarga de alguna de las actualizaciones implica que el Usuario Cliente renuncia
        voluntariamente a su derecho de utilizar cualquier versión anterior de la Aplicación.</p>
    <p>8.2 La Aplicación ha sido programada de tal modo que es capaz de conectarse automáticamente a Internet y buscar
        actualizaciones disponibles, las cuales pueden descargarse automáticamente, siempre y cuando la configuración
        del dispositivo así lo permita.</p>
    <p>8.3 Gazelle se reserva el derecho de suprimir y/o modificar el contenido de la Aplicación, cuando así lo
        considere necesario, a efectos de cumplir con los estándares comerciales de Gazelle así como con las
        disposiciones legales que fuesen aplicables.</p>
    <p>8.4 La actualización de la Aplicación no tiene un costo, sin embargo, podría haber costos asociados al plan de
        datos del Usuario Cliente para dicho proceso de descarga, siendo esto última responsabilidad plena del Usuario
        Cliente. </p>

    <h5>9. CONEXIÓN A INTERNET Y SERVICIOS EN LÍNEA:</h5>
    <p>9.1 La Aplicación requiere estar conectada a Internet para su funcionamiento.</p>
    <p>Asimismo, es capaz de hacer que su equipo (teléfono celular, móvil o tableta) se conecte automáticamente a
        Internet para notificar a Gazelle que la Aplicación se ha instalado correctamente y que se encuentra en adelante
        habilitada para utilizar sus funciones, así como realizar solicitudes de servicios. </p>
    <p>De igual modo, requiere contar con la función de GPS activada, en este sentido la aplicación solicitará al
        Usuario Cliente activar al GPS de su dispositivo, así como el acceso a su ubicación actual. Esto con la
        finalidad de poder ubicar al cliente en una zona específica y determinar la cobertura del servicio si su
        ubicación actual está dentro de la misma.</p>
    <p>Así también cada vez y solo cuando el Usuario Cliente registre el alta un viaje y mientras esté en curso, la
        aplicación tendrá acceso a su ubicación cada 20 segundos para poder hacer el seguimiento del viaje, esto con la
        finalidad de monitorear el viaje en caso de presentarse alguna eventualidad fuera del protocolo del servicio
        como accidentes, problemas técnicos entre otros. La aplicación solo tendrá acceso a la ubicación del Usuario
        Cliente solo cuando tenga un viaje en curso, si el viaje es cancelado o no se registra ninguno, la aplicación no
        tendrá acceso a esta información.</p>
    <p>Cuando la Aplicación se conecta automáticamente a Internet, se envía a un sitio web de Gazelle una dirección de
        protocolo de Internet asociada a su conexión de Internet actual. </p>

    <h5>10. RENUNCIA DE GARANTÍAS:</h5>
    <p>10.1 El Usuario Cliente reconoce que Gazelle le otorga un derecho de uso de la Aplicación en el estado en el que
        se encuentra y como fue proporcionada por el creador, Gazelle realizará sus mayores esfuerzos para que el
        funcionamiento del aplicativo se realice siempre en óptimas condiciones</p>
    <p>10.2 Ambas partes declaran que la relación entre el Socio Conductor y los Usuarios Clientes es completamente
        independiente de Gazelle. En consecuencia, Gazelle no es bajo ninguna circunstancia responsable, ni directa ni
        indirectamente, por daños, perjuicios, reclamaciones, quejas, pérdidas o costos de cualquier tipo derivados de
        hechos realizados por terceros, caso fortuito y/o fuerza mayor, incluyendo los ocurridos en la relación jurídica
        independiente antes descrita, sin perjuicio de lo cual se guardará colaboración hacia las partes afectadas, así
        como a las autoridades si fuera requerido.</p>
    <p>10.3 Gazelle no hace declaración o garantía alguna sobre el contenido de la Aplicación ni la veracidad,
        integridad o exhaustividad de la información contenida en la Aplicación o la información que proporcionen otros
        Socios Conductores o Usuarios Clientes, incluyendo información respecto a la honorabilidad, ubicación e
        identidad de algún Socio Conductor. La información, consejos, conceptos, calificaciones, evaluaciones y
        opiniones publicadas en la Aplicación no necesariamente reflejan la posición de Gazelle, ni de sus empleados,
        oficiales, directores, accionistas, licenciatarios, licenciantes, proveedores y afiliadas. Por esta razón,
        Gazelle, no se hace responsable por alguna información, imagen, audio, video, consejos, opiniones y/o conceptos
        que se emitan y/o publiquen en la Aplicación, sus redes sociales, web y cualquier otro espacio que permita la
        interacción entre Usuarios y entre Usuarios y terceros.</p>
    <p>10.4 Gazelle no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario Cliente causados por fallas
        en el sistema, en el servidor o en Internet. Gazelle tampoco será responsable por cualquier virus que pudiera
        infectar los equipos del Usuario Cliente como consecuencia del acceso, uso o examen de su sitio web o a raíz de
        cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios
        Clientes NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios
        resultantes de dificultades técnicas o fallas en los sistemas o en Internet. Gazelle no garantiza el acceso y
        uso continuado o ininterrumpido de la Aplicación. El sistema puede eventualmente no estar disponible debido a
        dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a Gazelle; en tales casos
        se procurará restablecerlo con la mayor celeridad posible, sin que por ello pueda imputársele algún tipo de
        responsabilidad.</p>

    <h5>11. RESPONSABILIDAD POR PÉRDIDA DE OBJETOS:</h5>
    <p>Por medio de los presentes Términos y Condiciones, Gazelle pone en conocimiento de los Usuarios Clientes las
        responsabilidades asumidas como empresa dedicada al rubro tecnológico. En ese sentido, nos comprometemos a
        colaborar en brindarles la información que esté a nuestra disposición respecto a los Socios Conductores, sobre
        posibles objetos perdidos durante los servicios de traslado, siempre con respeto a lo establecido por la ley de
        protección de datos personales y demás normatividad vigente. Les recordamos que, de acuerdo a lo estipulado en
        los presentes Términos y Condiciones, Gazelle queda exento de cualquier responsabilidad civil, penal y/o
        administrativa por la pérdida de cualquier objeto perteneciente al Usuario Cliente.</p>
    <p>En el caso de que tengamos en custodia algún objeto y/o elemento entregado en nuestras oficinas por Socios
        Conductores, estos se encontrarán a disposición de sus propietarios por el plazo de sesenta (60) días
        calendario. Vencido este plazo, Gazelle no se hace responsable por la custodia de los referidos objetos. </p>
    <p>Gazelle se reserva el derecho de solicitar al Usuario Cliente algún medio probatorio (factura de compra u otro
        pertinente) que pueda demostrar la propiedad del elemento.</p>

    <h5>12. LEY APLICABLE Y JURISDICCIÓN</h5>
    <p>Los presentes Términos y Condiciones se regirán por las leyes de la República del Perú. Cualquier disputa o
        controversia relacionada con estos Términos y Condiciones se resolverá en los tribunales competentes. Ambas
        partes renuncian a cualquier otra jurisdicción que pudiere corresponderles.</p>

    <h5>13. PLAZO Y TERMINACIÓN:</h5>
    <p>13.1 Estos Términos y Condiciones permanecerán vigentes por tiempo indefinido. </p>
    <p>El Usuario Cliente o Gazelle podrán terminar la presente relación jurídica en cualquier momento, mediante simple
        notificación por correo electrónico a soporte@gazelledriver.com, aviso a través de su Aplicación o por escrito
        entregada a la otra parte con al menos 24 (veinticuatro) horas de anticipación a la terminación, salvo que se
        haya pactado casos de terminación inmediata. La resolución de los Términos y Condiciones implicará que el
        derecho y autorización del Usuario Cliente para utilizar la Aplicación quedará sin efecto, por lo que este se
        compromete a eliminar la Aplicación de su teléfono celular, móvil o tableta en el momento en que este documento
        fuese resuelto.</p>

    <h5>14. DISPOSICIONES GENERALES:</h5>
    <p>14.1 Gazelle y el Usuario Cliente dejan constancia que las secciones o disposiciones de los Términos y
        Condiciones son separables y que la nulidad, ineficacia o invalidez de una o más de ellas no perjudicará a las
        restantes, las cuales permanecerán como válidas, eficaces y vinculantes.</p>
    <p>14.2 Las secciones 9, 10, 12 y 13 seguirán en vigor después de la terminación de los Términos y Condiciones por
        cualquier causa.</p>
    <p>14.3 Estos Términos y Condiciones podrán ser modificados en cualquier momento por Gazelle. El Usuario Cliente
        tendrá derecho a terminar la presente relación jurídica si no está de acuerdo con las modificaciones. En caso de
        que el Usuario Cliente continúe utilizando la Aplicación Gazelle, después de haber recibido las modificaciones,
        se entenderá que el Usuario Cliente ha aceptado las modificaciones.</p>
    <p>14.4 La aceptación de los presentes Términos y Condiciones representa el acuerdo completo entre Gazelle y el
        Usuario Cliente en relación con la Aplicación y reemplaza cualquier representación, conversación, compromiso,
        comunicación o publicidad anterior en relación con la Aplicación Gazelle. Esto también es aplicable en el
        momento que se publiquen las modificaciones a los Términos y Condiciones. </p>
    <p>14.15 En caso el Usuario Cliente desee comunicarse con Gazelle, ya sea por consultas, reclamos y/o quejas, deberá
        enviar un correo electrónico soporte@gazelledriver.com</p>

    <h5>15. POLÍTICAS DE PRIVACIDAD:</h5>
    <p>15.1 Responsable de sus Datos Personales. Gazelle, será el único responsable del tratamiento, uso, almacenamiento
        y divulgación de sus datos personales, conforme a lo establecido en las presentes políticas de privacidad.</p>
    <p>15.2 Datos Personales que recaba y almacena Gazelle.</p>
    <p>Los datos personales que Gazelle podrá recabar serán:</p>
    <p>I. Nombre completo;</p>
    <p>II. Fecha en la que descargó la Aplicación y fecha de registro;</p>
    <p>III. Número de teléfono celular;</p>
    <p>IV. Correo electrónico;</p>
    <p>V. Imagen o foto de perfil de Facebook y Gmail vinculada a la cuenta de Usuario Cliente;</p>
    <p>VI. Modelo, marca, sistema operativo y características del teléfono móvil o celular o tableta del Usuario
        Cliente;</p>
    <p>VII. Dirección IP;</p>
    <p>VIII. Dirección física, fecha y hora en el que el Usuario Cliente solicita un servicio a través de la Aplicación;
    </p>
    <p>IX. Dirección física, fecha y hora en la que el Usuario Cliente aborda un vehículo para un servicio solicitado a
        través de la Aplicación, así como el destino al cual se dirige, el trayecto y la ruta por la cual se dirige a
        dicho destino y la dirección física en la que el Usuario Cliente culmine el servicio;</p>
    <p>X. Búsquedas y solicitudes de servicios que el Usuario Cliente haya realizado a través de la Aplicación;</p>
    <p>XI. Servicios solicitados y servicios completados a través de la Aplicación;</p>
    <p>XII. Ubicación del teléfono móvil, celular o tableta en tiempo real lo que puede revelar la ubicación del Usuario
        Cliente en tiempo real (Geolocalización), datos que serán válidos desde que se solicita el viaje hasta que el
        Socio Conductor finalice el mismo o el Usuario Cliente lo cancele por algún motivo. Adicionalmente el celular
        deberá encontrarse encendido y con el servicio de GPS activo para poder hacer uso del servicio;</p>
    <p>XIII. Calificaciones, opiniones o comentarios que realicen los Socios Conductores o terceros respecto al Usuario
        Cliente;</p>
    <p>XIV. Calificaciones, opiniones o comentarios que el Usuario Cliente haga de Socios Conductores o terceros; y</p>
    <p>XV. Socios Conductores que el Usuario Cliente haya seleccionado (incluyendo datos del Socio Conductor y del
        vehículo empleado).</p>
    <p>XVI. Los datos técnicos de llamadas entre el Usuario Cliente y el Socio Conductor, siempre y cuando se realicen
        por la Aplicación.</p>
    <p>XVII. Los mensajes entre el Usuario Cliente y el Socio Conductor, siempre y cuando se realicen por la Aplicación.
    </p>
    <p>XVIII. Salvo lo descrito en el presente documento, Gazelle no recaba datos sensibles. Si el Usuario Cliente
        publica o postea cualquier dato sensible en la Aplicación será bajo su absoluta responsabilidad y Gazelle se
        reserva el derecho de suprimirlo.</p>
    <p>XIX. El Usuario Cliente podrá compartir su viaje con terceros, siendo bajo su responsabilidad la entrega de dicha
        información.</p>
    <p>15.3 Formas de Obtener los Datos Personales. Gazelle obtiene los datos personales directamente del Usuario
        Cliente, de terceros, de Socios Conductores, de fuentes públicas o en forma automática a través de la Aplicación
        y cookies u otras funcionalidades que permiten ubicarlo e identificarlo.</p>
    <p>15.4 Uso de sus Datos Personales. Los datos personales con los que contará Gazelle se administrarán y se tratarán
        para las siguientes finalidades, las que son estrictamente necesarias para el cumplimiento de la relación
        jurídico-comercial que el Usuario Cliente mantiene con Gazelle y para el uso de la Aplicación de acuerdo con sus
        funcionalidades. El Usuario Cliente manifiesta aceptar y dar consentimiento libre, previo, expreso, inequívoco e
        informado para el tratamiento de sus datos personales a favor de Gazelle para los siguientes fines:</p>
    <p>I. Permitir la coordinación y el reconocimiento por parte del Socio Conductor cuando el Usuario Cliente solicite
        un servicio a través de la Aplicación;</p>
    <p>II. Medir la calidad de los servicios de los Socios Conductores que el Usuario Cliente haya contratado y
        canalizar adecuadamente sus solicitudes de servicio;</p>
    <p>III. Analizar y evaluar sus características y cumplimiento como Usuario Cliente;</p>
    <p>IV. Monitorear el debido cumplimiento contractual;</p>
    <p>V. Atender cualquier queja, pregunta o comentario del Usuario Cliente;</p>
    <p>VI. Para solicitarle y compartir sus opiniones, comentarios y calificaciones de Usuarios Clientes con otros
        Usuarios Clientes que tengan autorización de uso de la Aplicación;</p>
    <p>VII. Para compartir su ubicación e información con los Socios Conductores que cuenten con la Aplicación Gazelle
        Driver y que éstos puedan evaluarlo y contactarlo cuando el Usuario Cliente envíe solicitudes de servicios a
        través de la Aplicación;</p>
    <p>VIII. Para compartir las evaluaciones, calificaciones, comentarios u opiniones que terceros hagan respecto del
        Usuario Cliente con solicitante potenciales y Usuarios que cuenten con la Aplicación Gazelle cliente;</p>
    <p>IX. Fines estadísticos;</p>
    <p>X. Mejorar la calidad de la Aplicación y los servicios de Gazelle; </p>
    <p>XI. Enviar notificaciones relacionadas con las modificaciones o actualizaciones que se realicen a las presentes
        políticas de privacidad o los Términos y Condiciones; y/o,</p>
    <p>XII. Investigar situaciones relacionadas con actos que puedan estar asociados a fraude, soborno, corrupción,
        lavado de dinero, financiamientos al terrorismo, robo u otros similares.</p>
    <p>XIII. Para el tratamiento de cualesquiera presuntos incidentes reportados como “emergencia” por el Socio
        Conductor del servicio solicitado por el Usuario Cliente y en estricto rigor en los incidentes reportados como
        presunta: agresión física, agresión sexual, accidente vehicular, robo, hurto, objeto olvidado y delito contra la
        vida u otros delitos conexos. En dichos supuestos, el Usuario Cliente acepta compartir su Documento Nacional
        Identidad (y los datos contenidos en éste) y su celular móvil, en favor del Socio Conductor que reporta este
        hecho a efectos que este último pueda ejercer su derecho a la tutela jurisdiccional efectiva ante las
        autoridades competentes.</p>
    <p>15.5 En adición a lo anterior, al registrarse el Usuario Cliente en la Aplicación Gazelle cliente, se entenderá
        que acepta de manera expresa que los datos de contacto que proporcione podrán ser utilizados para enviar
        notificaciones, avisos, propaganda o publicidad sobre productos de Gazelle o de terceros relacionados o
        asociados a Gazelle y para envío de información, revistas y noticias o de temas que Gazelle considere que pueden
        interesarle, así como noticias, comunicaciones o publicidad de Gazelle o sus empresas relacionadas.</p>
    <p>15.6 En caso de que no se desee autorizar el uso de sus datos personales para las finalidades en este párrafo, el
        Usuario Cliente podrá revocar su consentimiento en cualquier momento enviándonos una notificación a la siguiente
        dirección de correo electrónico a soporte@gazelledriver.com</p>
    <p>15.7 Los datos personales que se recaben, formarán parte de una base de datos que permanecerá vigente y éstos se
        conservarán mientras no se solicite la cancelación por el titular del dato, siendo que de presentarse dicho
        supuesto sus datos se cancelarán y bloquearán, para su posterior supresión de conformidad con la Ley de
        Protección de Datos Personales, Ley Nº29733 y su norma reglamentaria.</p>
    <p>El usuario acepta que se recopilen los datos personales ingresados en la aplicación Gazelle cliente y que estos
        puedan ser utilizados por terceros proveedores, sean nacionales, con la finalidad de mejorar la calidad de la
        aplicación y servicios de Gazelle, así como prevenir cualquier potencial situación de fraude que pueda
        ocasionarse dentro de la aplicación.</p>
    <p>15.8 ¿Con quién compartimos sus datos personales? Asimismo, Gazelle le informa que sus datos personales podrán
        ser transferidos y tratados dentro de Perú, para las finalidades establecidas en las presentes políticas de
        privacidad y para poder cumplir con las obligaciones legales de Gazelle frente al Usuario Cliente. En ese caso
        el Usuario Cliente declara conocer, manifiesta su conformidad y declara su consentimiento para que su
        información personal puede ser compartida con: Empleados de Gazelle, contratistas, prestadores de servicio y
        asesores de Gazelle; Filiales, accionistas y subsidiarias de Gazelle; Auditores externos, proveedores de
        servicios, compañías de informática y terceros que procesan la información por cuenta de Gazelle; Autoridades en
        Perú; y a cualquier otra persona autorizada por la ley o el reglamento aplicable.</p>
    <p>15.9 De conformidad con lo establecido, Gazelle hará del conocimiento de dicho tercero los Términos y Condiciones
        de las presentes políticas de privacidad a efecto de salvaguardar los datos personales de los Usuarios Clientes.
        Gazelle no requiere el consentimiento del Usuario Cliente para hacer estas transferencias salvo que las mismas
        se hagan para una finalidad que sea diferente a las mencionadas anteriormente.</p>
    <p>15.10 Asimismo, sus datos de contacto, ubicación e información general, así como las opiniones, comentarios,
        evaluaciones y calificaciones que respecto al Usuario Cliente hagan los Socios Conductores o usuarios de la
        Aplicación Gazelle Driver, será compartida con Socios Conductores y usuarios que cuenten con la Aplicación
        Gazelle Driver. Si el Usuario Cliente no desea que su información sea compartida con dichos Socios Conductores
        que cuenten con la Aplicación Gazelle Driver entonces el Usuario Cliente no podrá utilizar la Aplicación ya que
        es un requisito esencial para que el Usuario Cliente pueda enviar solicitudes de servicio a través de la
        Aplicación.</p>
    <p>15.11 Esta manifestación de voluntad que realiza aceptando la transferencia de sus datos personales, puede ser
        luego revocada por el Solicitante en cualquier momento, enviándonos un correo electrónico a
        soporte@gazelledriver.com</p>
    <p>15.12 Medidas de seguridad de datos personales. Gazelle actuará con diligencia debida en la protección de datos
        personales de sus Usuarios Clientes, y adoptará todas y cada una de las medidas de seguridad administrativas,
        físicas y técnicas que establezca la normatividad aplicable para salvaguardar su información personal, por lo
        que de igual manera y en caso de que un tercero necesite conocer de dicha información, Gazelle obligará a dichos
        terceros a cumplir con las presentes políticas de privacidad, así como a adoptar las medidas de seguridad
        administrativas, físicas y técnicas establecidas en la normatividad aplicable, a fin de proteger sus datos
        personales de cualquier daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.</p>
    <p>Sin perjuicio de lo anterior, si bien Gazelle maneja un sistema de base de datos que cumple con la diligencia y
        cuidado debido de seguridad, el Usuario Cliente reconoce que las comunicaciones electrónicas no son
        absolutamente seguras y que cualquier sistema informático, por más que se encuentre protegido con una debida
        diligencia, se encuentra expuesto a eventuales vulneraciones que pudiesen afectar su funcionamiento o seguridad.
        En consecuencia, Gazelle no garantiza que no se produzcan daños, pérdidas, destrucciones, extravíos,
        alteraciones o tratamientos no autorizados producto de las referidas vulneraciones.</p>
    <p>15.13 Medios para ejercer los derechos de acceso, rectificación, oposición y cancelación. El Usuario Cliente
        tiene derecho de acceder a sus datos personales que se encuentren en la base de datos de Gazelle y a los
        detalles del tratamiento de los mismos , incluyendo la individualización de la persona/s o agencia/s a las
        cuales dichos datos personales son regularmente transmitidos, así como a rectificarlos en caso de ser inexactos
        o incompletos, o cancelarlos cuando considere que resulten ser excesivos o innecesarios para las finalidades que
        justificaron su obtención u oponerse al tratamiento de los mismos para los fines específicos que no sean
        necesarios para la relación jurídica con Gazelle.</p>
    <p>En caso de que el Usuario Cliente, como titular de sus datos personales desee ejercer cualquiera de los derechos
        antes mencionados, deberá enviar una solicitud en tal sentido al Encargado de Tratamiento de Datos Personales de
        Gazelle a través de la siguiente dirección de correo electrónico: soporte@gazelledriver.com</p>
    <p>Dicha solicitud deberá contener como mínimo la siguiente información: (a) nombre y domicilio, a efecto de dar
        respuesta a su solicitud en los plazos establecidos en la ley aplicable; (b) copia de su Documento Nacional de
        Identidad, o cualquier otro documento que acredite su identidad o acredite la personalidad de su representante
        legal, y en caso que la solicitud se realice a través del representante legal, acompañar el documento que
        acredita sus facultades como representante; (c)descripción clara y precisa de los datos personales sobre los que
        se busca ejercer alguno de los derechos antes mencionados de acceso, rectificación, oposición, cancelación o
        revocación, así como la descripción clara y precisa de su solicitud; (d) fecha y firma del solicitante y (e)
        cualquier otro elemento o documento que facilite la localización de dichos datos personales.</p>
    <p>Gazelle responderá cualquier solicitud completa en un plazo máximo de diez (10) días calendario o el máximo
        permitido por la ley, pudiendo ser ampliado por un plazo igual por razones debidamente justificadas. La
        respuesta de Gazelle indicará si la solicitud de acceso, rectificación, cancelación u oposición es procedente y,
        en caso que así lo sea, Gazelle hará efectiva la determinación de manera automática. Gazelle proporcionará
        copias electrónicas de su información personal en caso de que el Usuario Cliente ejerza su derecho de acceso.
    </p>
    <p>15.14 ¿Qué opciones tiene para limitar el uso y divulgación de sus datos? El uso de sus datos personales para
        aquellas finalidades que no son estrictamente necesarias para el cumplimiento de su relación con Gazelle y para
        limitar la manera en la que estime conveniente su uso y divulgación. El Usuario Cliente podrá revocar o limitar
        su consentimiento para la utilización de sus datos personales para finalidades que no son estrictamente
        necesarias para el cumplimiento de la relación con Gazelle o para que compartamos sus datos personales con
        personas o finalidades para las cuales requerimos su consentimiento a través de un correo electrónico a
        soporte@gazelledriver.com</p>
    <p>Particularmente, según lo establecido en la normatividad vigente, el Usuario Cliente tiene derecho a dejar de
        recibir mensajes de publicidad en su correo electrónico, teléfono o por cualquier otro medio a través de los
        procedimientos que hemos implementado. El Usuario Cliente puede contactar a Gazelle en la forma que se indica
        anteriormente para estos efectos.</p>
    <p>15.15 Redes Sociales. Gazelle podrá contar con un perfil, ya sea de la empresa o de sus productos, en las
        principales redes sociales tales como Facebook, Instagram (tal como se ha mencionado en los Términos y
        Condiciones, las “Redes Sociales”).</p>
    <p>Las Redes Sociales, servirán única y exclusivamente como un canal de comunicación inmediato con los seguidores de
        Gazelle o sus productos, así como un medio electrónico de información y marketing de nuevos productos ofertas y
        promociones que sean realizadas por Gazelle exclusivamente en su perfil y que será accesible a través del mismo
        a sus seguidores. Gazelle únicamente podrá conocer los datos personales que así lo permitan las Redes Sociales y
        en ningún caso extraerá los datos personales que pueda conocer de sus seguidores sin su consentimiento previo.
    </p>
    <p>Por otro lado, la Aplicación puede contener enlaces a Redes Sociales a través de las cuales el Usuario Cliente
        puede compartir información personal. Hacemos de su conocimiento que tanto las Redes Sociales serán los
        titulares de las bases de datos que a éstos les corresponda, por lo que Gazelle le solicita leer las políticas
        de privacidad y condiciones de uso de las mismas.</p>
    <p>15.16 Modificaciones a las presentes políticas de privacidad. En relación con lo anterior Gazelle se reserva el
        derecho a modificar los términos y condiciones de estas políticas de privacidad, acción que será publicada a
        través del sitio web de Gazelle: https://gazelledriver.com/ al que tendrá acceso cualquier persona que se
        encuentre interesada. De cualquier forma, Gazelle le recomienda que revise estas políticas de privacidad cada
        vez que haga uso de la Aplicación, a efecto de saber los cambios, modificaciones o actualizaciones que se
        realicen al mismo.</p>

    <h5>EL SUSCRITO, OTORGA SU ACEPTACIÓN Y CONSENTIMIENTO A LOS TÉRMINOS Y CONDICIONES, INCLUYENDO EL AVISO DE
        PRIVACIDAD Y ENVÍO DE INFORMACIÓN, PROMOCIONES Y/O PUBLICIDAD, AL PRESIONAR “CLIC” EN EL BOTÓN ACEPTAR DESDE LA
        APLICACIÓN GAZELLE APP – CLIENTE.</h5>

    <div class="spacer"></div>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>