import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        MatIconModule
    ],
    exports: [
        MatIconModule
    ],
})
export class MaterialModule { }