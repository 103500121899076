<style>
    h3 {
        color: #000000;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .form-box-lg {
        display: inline-block;
        width: 100%;
        /* max-width: 500px; */
        text-align: left;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        background-color: #fafafa;
        padding: 40px 30px;
        color: #000000 !important;
    }

    .form-box-lg h3 {
        color: #222222 !important;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
    }

    .form-box-lg h4 {
        text-align: left !important;
        color: #444444 !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin: 20px 0 12px;
    }

    .form-box-lg h5 {
        text-align: left !important;
        color: #444444 !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        margin: 20px 0 12px;
    }

    .form-box-lg p {
        text-align: left !important;
        color: #000000 !important;
        font-size: 14px;
        margin: 0 0 12px 0;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box-lg">

    <h3>POLÍTICA DE PRIVACIDAD PARA USUARIOS DE LA APLICACION “GAZELLE APP” Y “GAZELLE DRIVER”</h3>

    <p class="mb-3">
        Actualizado el 27 de agosto de 2021.
    </p>

    <h5>I. Introducción</h5>
    <p>Cuando usted usa Gazelle App nos confía sus datos personales. Por ello nos comprometemos a mantener esa
        confianza. En este aviso se describen los datos personales que recopilamos cuando usted usa nuestro servicio,
        cómo los usamos y compartimos, y las opciones que tiene con respecto a ellos. </p>

    <h4>II. Descripción general</h4>
    <h5>A. Alcance</h5>
    <p>Este aviso se aplica a todos los usuarios de los servicios de Gazelle App en cualquier parte del mundo, incluidos
        los que usan las apps, sitios web, funciones u otros servicios.</p>
    <p>Este aviso describe cómo Gazelle App y sus afiliados recopilan y usan los datos personales. También se aplica a
        todos los usuarios de nuestras apps, sitios web, funciones y otros servicios en cualquier lugar del mundo. Se
        aplica específicamente a las siguientes partes:</p>
    <p>Usuarios clientes: personas que piden viajes o que se transportan, incluidos quienes reciben los servicios
        programados por un tercero.</p>
    <p>Socios conductores: personas que brindan servicios de transporte a los usuarios de manera individual o a través
        de empresas asociadas.</p>
    <p>Se hará referencia a todas las personas sujetas a este aviso como “usuarios”.</p>
    <p>Además, tenga en cuenta lo siguiente:</p>
    <p>Nuestras prácticas están sujetas a las leyes vigentes en los lugares donde operamos. Esto significa que aplicamos
        las prácticas descritas en el presente documento en un país o en una región solo si sus leyes lo permiten.
        Contáctenos aquí o a través de las direcciones a continuación si tiene preguntas sobre nuestras prácticas en un
        lugar específico.</p>

    <h4>III. Recopilación y usos de los datos</h4>
    <h5>A. Datos recopilados</h5>
    <p>Gazelle App recopila los siguientes datos personales: </p>
    <p>Datos que los usuarios proporcionan a Gazelle App, como los que brindan cuando crean sus cuentas.</p>
    <p>Datos que se generan cuando se utilizan nuestros servicios, como los detalles del dispositivo, ubicación y uso de
        la app.</p>
    <p>Los siguientes datos personales son recopilados por Gazelle App o en su nombre:</p>

    <h5>1. Datos proporcionados por usuarios. Incluye lo siguiente:</h5>
    <p>Perfil de usuario: Recopilamos los datos que brindan los usuarios cuando crean o actualizan sus cuentas Gazelle
        App, lo que puede incluir su nombre, correo electrónico, número de teléfono, foto de perfil, licencia de
        conducir y otros documentos de identificación que muestren la foto, género, fecha de nacimiento y número de
        documento. También incluye información del vehículo o del seguro de los socios conductores, configuración del
        usuario y certificados de salud o aptitud física para prestar servicios a través de las apps de Gazelle App.</p>
    <p>Podemos usar las fotos enviadas por los usuarios para comprobar sus identidades mediante tecnologías de
        verificación facial. Para conocer más, consulte la sección Cómo usamos los datos personales.</p>
    <p>Verificación de antecedentes y de identidad (socios conductores): Esto puede incluir información como el
        historial de conducción, antecedentes penales (cuando lo permita la ley), estado de la licencia, direcciones
        anteriores y autorización para generar ganancias. Un proveedor autorizado puede recopilar esta información en
        nombre de Gazelle App. </p>
    <p>Datos demográficos: Podemos recopilar información demográfica sobre los usuarios, incluso a través de encuestas
        destinadas a ellos. En algunos países también podemos recibir datos demográficos sobre usuarios que provengan de
        terceros.</p>
    <p>Contenido del usuario: Recopilamos los datos que los usuarios envían cuando se contactan con el soporte al
        cliente de Gazelle App, brindan calificaciones o reconocimientos a otros usuarios o se comunican con Gazelle App
        de alguna otra manera. Esto puede incluir comentarios, fotografías u otras grabaciones que recopilan los
        usuarios, incluidas de audio o de video y que envían a soporte al cliente. También se incluyen los metadatos
        relacionados con el método que se usa para contactar a Gazelle App.</p>

    <h5>2. Datos creados durante el uso de nuestros servicios. Incluye lo siguiente: </h5>
    <p>Datos de ubicación (socio conductor): Recopilamos datos de ubicación precisos o aproximados de los socios
        conductores, p. ej., para permitir que se realicen viajes o entregas, activar el seguimiento de viajes, entregas
        o funciones de seguridad, prevenir y detectar fraudes, y cumplir con requisitos legales. Gazelle App recopila
        esta información cuando la app de Gazelle App se ejecuta en primer plano (app abierta y en pantalla) o en
        segundo plano (app abierta, pero no en pantalla) en su dispositivo móvil.</p>
    <p>Datos de ubicación (usuarios clientes): Recopilamos datos de ubicación precisos o aproximados de los usuarios y
        de los clientes para permitir y mejorar el uso de nuestras apps, incluso para optimizar el inicio de un viaje o
        la recolección de un envío, facilitar las entregas, activar las funciones de seguridad, y prevenir y detectar el
        fraude. </p>

    <p>Recopilamos dichos datos de los dispositivos móviles de los usuarios si se nos autoriza a hacerlo. (Consulte la
        sección Opciones y transparencia a continuación para conocer cómo los usuarios clientes y los socios conductores
        pueden permitir la recopilación de datos de ubicación). Gazelle App recopila esta información desde el momento
        en que se pide un viaje o una entrega hasta que se finaliza (y esta recopilación se puede indicar con un icono o
        una notificación en el dispositivo móvil, según el sistema operativo) y cada vez que la app se ejecuta en primer
        plano (app abierta y en pantalla) en el dispositivo móvil.</p>
    <p>Información de transacciones: Recopilamos datos de las transacciones relacionadas con el uso de nuestros
        servicios, lo que incluye el tipo de servicio solicitado, fecha y hora de la prestación, detalles del viaje,
        método e información de pago, importe cobrado y distancia recorrida.</p>
    <p>Datos de uso: Recopilamos información sobre cómo los usuarios interactúan con nuestros servicios. Esto incluye
        datos como la fecha y la hora de acceso, funciones de la app o páginas vistas, fallos de la app y otras
        actividades del sistema, y tipo de navegador. También podemos recopilar datos sobre los servicios o los sitios
        de terceros que se usan antes de interactuar con nuestros servicios, los que utilizamos con fines de marketing.
        (Consulte Cómo usamos los datos a continuación para conocer más sobre cómo comercializamos nuestros servicios a
        los usuarios).</p>
    <p>Datos del dispositivo: Podemos recopilar datos sobre los dispositivos usados para acceder a nuestros servicios,
        lo que incluye modelos de hardware, dirección IP u otros identificadores únicos del dispositivo, sistemas
        operativos y versiones, software, idiomas preferidos, identificadores de publicidad e información de las redes
        móviles o de movimiento del dispositivo.</p>
    <p>Grabaciones de seguridad: En ciertas jurisdicciones y donde la ley lo permita, los usuarios pueden grabar el
        audio de sus viajes función en la app “Grabar audio del dispositivo”. Las grabaciones se cifran y almacenan en
        la app de los dispositivos de los usuarios, y se envían a Gazelle App, pero solo son utilizados si el usuario
        cliente o socio conductor lo autoriza por un incidente de seguridad. </p>

    <h5>3. Datos de otras fuentes. Entre estas se incluyen las siguientes:</h5>
    <p>Titulares de cuentas Gazelle App que soliciten servicios para otros usuarios o en nombre de estos, o que permiten
        que estos pidan o reciban servicios a través de sus cuentas, incluidos los titulares de cuentas Gazelle App para
        Empresas.</p>
    <p>Usuarios u otras personas que proporcionan datos con relación a reclamos o a conflictos.</p>
    <p>Proveedores que nos ayudan a verificar la identidad de los usuarios, información de antecedentes y cumplimiento
        de los requisitos para generar ganancias, o que revisan las cuentas de los usuarios en relación con sanciones,
        lavado de dinero o exigencias relativas a la identificación del cliente. </p>
    <p>Proveedores de seguros, vehículos o servicios financieros para socios conductores.</p>
    <p>Empresas de transporte asociadas (para socios que usan nuestros servicios a través de una cuenta relacionada con
        dicha compañía).</p>
    <p>Fuentes disponibles públicamente.</p>
    <p>Proveedores de servicios de marketing o distribuidores de datos, cuya información Gazelle App usa con fines
        publicitarios o de investigación.</p>
    <p>Gazelle App puede combinar la información recopilada de estas fuentes con otros datos que posea.</p>

    <h5>B. Cómo usamos los datos personales</h5>
    <p>Gazelle App usa los datos personales para que los servicios de transporte y de envíos, así como otros productos y
        servicios, sean confiables y convenientes. También usamos estos datos para lo siguiente:</p>
    <p>• mejorar la seguridad de los usuarios y de los servicios</p>
    <p>• brindar soporte al cliente </p>
    <p>• realizar tareas de investigación y de desarrollo </p>
    <p>• permitir la comunicación entre usuarios </p>
    <p>• enviar comunicaciones de marketing y de otro tipo a los usuarios</p>
    <p>• abordar temas relacionados con procedimientos legales</p>
    <p>Gazelle App no vende ni divulga datos personales del usuario a terceros con fines de marketing directo, salvo que
        los usuarios den su consentimiento.</p>
    <p>Usamos los datos personales que recopilamos para lo siguiente:</p>

    <h5>1. Para prestar nuestros servicios.</h5>
    <p>Gazelle App usa esta información para prestar, personalizar, mantener y mejorar los servicios.</p>
    <p>Esto incluye el uso de los datos para lo siguiente:</p>
    <p><strong>Crear o actualizar cuentas.</strong></p>
    <p>Activar los servicios de transporte y de envío (p. ej., el uso de los datos de ubicación para facilitar un viaje
        o entrega), funciones que involucran el intercambio de información (como división de tarifa, función para
        compartir la llegada estimada, calificaciones y reconocimientos) y funciones de accesibilidad para simplificar
        el uso de nuestros servicios a personas con discapacidades.</p>
    <p><strong>Procesar pagos.</strong></p>
    <p>Hacer un seguimiento del progreso de los viajes o de los envíos y compartirlo.</p>
    <p>Facilitar soluciones de seguros, vehículos, facturación o financiación.</p>
    <p>Realizar las operaciones necesarias para mantener nuestros servicios, lo que incluye solucionar errores de
        software y problemas operacionales, realizar análisis, pruebas e investigación, y hacer un seguimiento y
        análisis de las tendencias de uso y actividades.</p>
    <p>Gazelle App realiza las actividades anteriores, incluso la recopilación y el uso de datos de ubicación a los
        fines de estas actividades, porque son necesarias para cumplir con nuestras obligaciones con los usuarios,
        conforme a los Términos de uso o a otros acuerdos con los usuarios.</p>

    <h5>2. Seguridad.</h5>
    <p>Usamos datos personales para ayudar a mantener la seguridad y la integridad de nuestros servicios y usuarios.
        Incluye lo siguiente:</p>
    <p>Verificar la identidad y el cumplimiento de los requisitos de los usuarios para brindar servicios de transporte o
        de entregas, incluso a través de la revisión de antecedentes, cuando lo permita la ley, a fin de evitar que
        socios conductores o usuarios clientes poco confiables usen nuestros servicios.</p>
    <p>Usar los datos de los dispositivos de socios conductores o para detectar comportamientos de conducción poco
        segura, como exceder la velocidad o frenar y acelerar bruscamente, e informar sobre prácticas más seguras.
        También usamos la información de los dispositivos de socios repartidores para verificar el tipo de vehículo que
        usaron para realizar las entregas.</p>
    <p>Usar los datos del dispositivo, ubicación, perfil y uso, entre otros, para prevenir, detectar y combatir el
        fraude. Esto incluye identificar cuentas o usos fraudulentos de nuestros servicios, evitar el uso de los
        servicios por parte de socios conductores o socios repartidores no autorizados, verificar la identidad de los
        usuarios relacionados con ciertos métodos de pago, y prevenir y combatir el acceso no autorizado a las cuentas
        de los usuarios.</p>
    <p>Usar las calificaciones, incidentes informados y otros comentarios para promover el cumplimiento de nuestras
        Guías comunitarias y como motivo para desactivar las cuentas de usuarios con bajas calificaciones o que
        infringen de otra manera dichas guías en ciertos países.</p>
    <p>Compartir información sobre incidentes graves de seguridad de socios conductores o sobre el cumplimiento de las
        normas locales con terceros, que incluye a otras empresas que permiten a los usuarios pedir o brindar servicios
        de viaje o de entregas, o a intermediarios que recopilan esos datos para varias compañías, a fin de evitar que
        los socios conductores o los socios repartidores que puedan suponer un riesgo para la plataforma o los usuarios
        utilicen los servicios de Gazelle App o de otras empresas. También podemos compartir estos datos con terceros,
        incluso con las personas afectadas por esos incidentes, si estos provocaron la desactivación de la cuenta.</p>
    <p>Usar calificaciones, datos de uso y de otro tipo para evitar la compatibilidad entre usuarios y socios
        conductores con mayor riesgo de conflicto (p. ej., porque uno calificó al otro con una estrella).</p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para cumplir con nuestras obligaciones con
        los usuarios, conforme a los Términos de uso o a otros acuerdos con los usuarios, o para satisfacer los
        intereses legítimos relacionados con la seguridad de Gazelle App o de otras partes, que incluyen a los usuarios
        y al público en general.</p>

    <h5>3. Soporte al cliente.</h5>
    <p>Gazelle App usa la información recopilada (que puede incluir grabaciones de llamadas) para brindar soporte al
        cliente, incluso para investigar y abordar las inquietudes de los usuarios, y para controlar y mejorar las
        respuestas y los procesos de soporte al cliente.</p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para cumplir con nuestras obligaciones con
        los usuarios, conforme a nuestros Términos de uso u otros acuerdos con los usuarios.</p>

    <h5>4. Investigación y desarrollo.</h5>
    <p>Podemos usar los datos que recopilamos en procesos de pruebas, investigaciones, análisis, desarrollo de productos
        y aprendizaje automático con el fin de optimizar la experiencia del usuario. Esto permite mejorar la seguridad
        de nuestros servicios, hacerlos más convenientes y fáciles de usar, y desarrollar nuevos servicios y funciones.
    </p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para cumplir con nuestras obligaciones con
        los usuarios, conforme a los Términos de uso o a otros acuerdos con los usuarios, mejorar nuestros servicios y
        funciones existentes, o satisfacer los intereses legítimos de Gazelle App relacionados con el desarrollo de
        nuevos servicios y funciones.</p>

    <h5>5. Comunicaciones no comerciales.</h5>
    <p>Gazelle App puede usar los datos personales que recopilamos a fin de generar recibos para los usuarios y
        proporcionárselos, informarles sobre cambios en nuestros términos, servicios o políticas, o enviar otras
        comunicaciones que no tienen el objetivo de comercializar los servicios o los productos de Gazelle App o de sus
        socios.</p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para cumplir con nuestras obligaciones con
        los usuarios, conforme a los Términos de uso o a otros acuerdos con los usuarios, o para satisfacer el interés
        legítimo de Gazelle App de brindar información a los usuarios sobre los eventos que pueden afectar el uso de sus
        servicios.</p>

    <h5>6. Procedimientos y requisitos legales.</h5>
    <p>Podemos usar datos personales para investigar o abordar reclamos o disputas relacionados con el uso de los
        servicios de Gazelle App, a fin de cumplir con los requisitos de las leyes, reglamentaciones, licencias o
        acuerdos de operación vigentes, o en virtud de un procedimiento legal o de una solicitud de Gazelle App, incluso
        de la fuerza del orden público.</p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para satisfacer los intereses legítimos de
        Gazelle App de investigar y de responder los reclamos y las disputas relacionados con el uso de los servicios y
        las funciones de Gazelle App, o para cumplir con los requisitos legales aplicables.</p>

    <h5>7. Toma de decisiones automatizada</h5>
    <p>Usamos datos personales para tomar decisiones automatizadas en relación con el uso de nuestros servicios. Incluye
        lo siguiente:</p>
    <p>Activación del precio dinámico, mediante el que se determina el monto de un viaje o el costo de envío con Gazelle
        App según factores que varían constantemente, como el tiempo y la distancia estimados, ruta prevista, tránsito
        esperado, y cantidad de usuarios y de socios conductores que usan Gazelle App en un momento determinado.</p>
    <p>Asignación de socios conductores disponibles a los usuarios clientes que soliciten servicios. A los usuarios
        clientes se les asignará un socio conductor según la disponibilidad, cercanía y otros factores, como la
        probabilidad de aceptar un viaje según las preferencias o los comportamientos anteriores. </p>
    <p>Marcas a las cuentas de los usuarios que se identifican como involucrados en casos de fraude, actividades poco
        seguras u otras actividades que podrían perjudicar a Gazelle App, sus usuarios y terceros. En algunas
        situaciones, como cuando un usuario presenta documentación fraudulenta o usa de manera indebida el programa de
        referidos de Gazelle App, este tipo de comportamientos puede ocasionar la desactivación automática. </p>
    <p>Uso de datos del socio conductor (como ubicación, calificación y género) e información del usuario cliente (como
        calificación, origen y destino) para evitar compatibilidades que puedan aumentar el riesgo de conflicto.</p>
    <p>Haga clic en los enlaces de esta sección para conocer más sobre los procesos. Para objetar una desactivación que
        resulte de ellos, contacte al soporte al cliente de Gazelle App.</p>
    <p>Gazelle App realiza las actividades anteriores porque son necesarias para cumplir con nuestras obligaciones con
        los usuarios, conforme a los Términos de uso o a otros acuerdos con los usuarios, o para satisfacer los
        intereses legítimos de Gazelle App, sus usuarios y terceros.</p>

    <h5>C. Cookies y tecnologías de terceros</h5>
    <p>Gazelle App y sus socios usan cookies y otras tecnologías de identificación en las apps, sitios web, correos
        electrónicos y anuncios en línea para los fines descritos en este aviso y en el Aviso de cookies de Gazelle App.
    </p>
    <p>Las cookies son pequeños archivos de texto que los sitios web, apps, medios en línea y anuncios almacenan en el
        navegador o en el dispositivo. Gazelle App usa cookies y tecnologías similares para los fines que se enumeran a
        continuación:</p>
    <p>• autenticar usuarios</p>
    <p>• recordar las preferencias y configuraciones del usuario</p>
    <p>• determinar la popularidad del contenido</p>
    <p>• brindar y medir la eficacia de las campañas de publicidad</p>
    <p>• analizar el tránsito y las tendencias del sitio para comprender los comportamientos en línea y los intereses de
        las personas que interactúan con nuestros servicios</p>
    <p>También podemos permitir que terceros realicen mediciones de audiencia y servicios analíticos para nosotros,
        presenten anuncios en representación nuestra a través de internet, y realicen un seguimiento y nos informen
        sobre el rendimiento de estos anuncios. Estas entidades pueden usar cookies, balizas web, SDK y otras
        tecnologías para identificar los dispositivos que usan las personas cuando visitan nuestros sitios web, así como
        cuando ingresan a otros servicios y sitios en línea.</p>

    <h5>D. Intercambio y divulgación de datos</h5>
    <p>Algunos de los servicios y las funciones de Gazelle App requieren que intercambiemos datos personales con otros
        usuarios o a pedido de estos. También podemos compartir esa información con nuestros afiliados, subsidiarias y
        socios por motivos legales o relacionados con reclamos o disputas.</p>
    <p>Gazelle App puede compartir los datos personales de las siguientes maneras:</p>
    <h5>1. Con otros usuarios</h5>
    <p>Esto incluye los siguientes datos:</p>
    <p>Nombre, calificación, punto de partida y destino de los usuarios clientes con los socios conductores.</p>
    <p>Nombre, dirección de entrega de los usuarios clientes con el socio conductor. También podemos compartir las
        calificaciones, comentarios u otra información que requiera la ley.</p>
    <p>En el caso de los socios conductores, podemos compartir datos con los usuarios clientes, incluidos su nombre y
        foto de perfil, marca, modelo, color, matrícula y foto del vehículo, ubicación (antes y durante el viaje),
        calificación promedio proporcionada por los usuarios, cantidad total de viajes, tiempo de uso desde que se
        registró como socio conductor, detalles de contacto (si lo permiten las leyes vigentes) y perfil del socio
        conductor, incluidos los reconocimientos y otros comentarios enviados por usuarios anteriores.</p>
    <p>También proporcionamos a los usuarios clientes recibos que incluyen información, como el mapa de ruta, desglose
        de los montos cobrados, y nombre y foto del socio conductor. También incluimos otros datos en los recibos si así
        lo exige la ley.</p>
    <h5>2. A pedido del usuario</h5>
    <p>Esto incluye compartir datos con las siguientes partes:</p>
    <p>Otras personas a pedido del usuario. P. ej., compartimos la llegada estimada y la ubicación de un usuario con un
        amigo, si así lo solicita, o los detalles del viaje de un usuario cliente cuando divide una tarifa con otra
        persona.</p>
    <p>Servicios de emergencias. Ofrecemos funciones que permiten a los usuarios compartir sus datos con la policía,
        bomberos y servicios de ambulancia en caso de emergencia o después de ciertos incidentes. </p>
    <h5>3. Con el público en general </h5>
    <p>Las preguntas o los comentarios de los usuarios que se envían a través de foros públicos, como los blogs y las
        redes sociales de Gazelle App, pueden estar al alcance de todos, incluidos los datos personales incorporados en
        las preguntas o los comentarios enviados por un usuario.</p>
    <h5>4. Con el titular de la cuenta Gazelle App</h5>
    <p>Si un usuario pide un viaje o comida a través de una cuenta que pertenece a otra persona, podemos compartir los
        detalles del envío o del viaje, incluidos los datos de ubicación, con el titular de la cuenta. Esto sucede, p.
        ej., en los siguientes casos:</p>
    <p>Un usuario realiza un viaje programado por un amigo o a través de un perfil familiar.</p>
    <h5>5. Por motivos legales o ante un conflicto</h5>
    <p>Gazelle App puede compartir los datos personales de los usuarios si así lo exige la ley, reglamentación, licencia
        o acuerdo de operación, proceso legal vigentes, o cuando la divulgación es apropiada debido a asuntos de
        seguridad o similares.</p>
    <p>Esto incluye compartir datos personales con la fuerza del orden público, agentes de salud pública, otras
        entidades, u otros terceros según sea necesario para hacer cumplir nuestros Términos del servicio, acuerdos del
        usuario u otras políticas, y proteger los derechos o la propiedad de Gazelle App, o los derechos, seguridad o
        propiedad de los demás, o en caso de un reclamo o una disputa relacionados con el uso de nuestros servicios.
    </p>
    <p>Esto también incluye compartir datos personales con terceros en caso de cualquier fusión con otra compañía (o
        durante las negociaciones), venta de activos empresariales, consolidación o reestructuración, financiamiento, o
        compra de todo nuestro negocio o una parte de él a cargo de otra empresa.</p>
    <h5>6. Con consentimiento</h5>
    <p>Gazelle App puede compartir los datos personales de un usuario de manera diferente a la descrita en este aviso si
        se lo notificamos y este acepta el intercambio de información.</p>

    <h5>E. Mantenimiento y eliminación de los datos</h5>
    <p>Gazelle App conserva los datos del usuario por el tiempo que sea necesario para cumplir con los fines descritos
        anteriormente.</p>
    <p>Los usuarios pueden solicitar la eliminación de sus cuentas en cualquier momento. Gazelle App puede conservar los
        datos del usuario después de recibir una solicitud para eliminar una cuenta debido a requisitos legales o
        reglamentarios o por los motivos que se establecen en esta política.</p>
    <p>Gazelle App conserva los datos del usuario por el tiempo que sea necesario para cumplir con los fines descritos
        anteriormente. Esto significa que conservamos las distintas categorías de datos por diferentes periodos, según
        el tipo de información, nivel del usuario con los que se relacionan y fines para los que se recopilan.</p>
    <p>Los usuarios pueden solicitar la eliminación de su cuenta en cualquier momento a través de una comunicación
        directa con el área de soporte de Gazelle App, enviando un correo electrónico con el asunto "Quiero eliminar mi cuenta" 
        a: <a href="mailto:gazelledriverapp@gmail.com">gazelledriverapp@gmail.com</a> o escribiendo un mensaje a través de WhatsApp 
        al número: <strong>+51913382210</strong>.</p>
    <p>Después de recibir la solicitud, Gazelle App elimina la cuenta y los datos del usuario, a menos que se conserven
        debido a requisitos legales o reglamentarios, con fines de seguridad o de prevención de fraude, o por un
        problema relacionado con la cuenta del usuario, como créditos pendientes o un reclamo o disputa sin resolver.
        Debido a que estamos sujetos a requisitos legales y reglamentarios relacionados con los socios conductores,
        conservaremos su cuenta y sus datos por un mínimo de 7 años después de una solicitud de eliminación. En el caso
        de los usuarios clientes, sus datos suelen eliminarse dentro de los 90 días posteriores a la solicitud, excepto
        cuando su conservación sea necesaria por los motivos descritos anteriormente.</p>

    <h4>IV. Opciones y transparencia</h4>
    <p>Gazelle App permite a los usuarios acceder o controlar los datos que recopila, incluso a través de los siguientes
        medios: </p>
    <p>• permisos del dispositivo</p>
    <p>Gazelle App también permite a los usuarios solicitar acceso o copias de sus datos, cambios, actualizaciones o
        eliminación de sus cuentas, o que Gazelle App restrinja el procesamiento de su información personal.</p>
    <h5>1. Permisos del dispositivo</h5>
    <p>La mayoría de las plataformas de dispositivos móviles (iOS, Android, etc.) definieron ciertos tipos de datos de
        los dispositivos a los que las apps no pueden acceder sin la autorización del propietario. Estas plataformas
        tienen diferentes métodos para obtener dichos permisos. Verifique la configuración disponible en su dispositivo
        o consulte con su proveedor.</p>

    <h4>V. Actualizaciones de este aviso</h4>
    <p>Es posible que actualicemos este aviso ocasionalmente. </p>
    <p>Es posible que actualicemos este aviso ocasionalmente. Si realizamos cambios significativos, se los notificaremos
        a los usuarios a través de la app de Gazelle App o por otros medios, como por correo electrónico. Recomendamos a
        los usuarios revisar periódicamente este aviso para obtener información actualizada sobre nuestras prácticas de
        privacidad.</p>
    <p>El uso de nuestros servicios después de una actualización constituye el consentimiento del aviso modificado en la
        medida en que la ley lo permita.</p>



    <div class="spacer"></div>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>