<style>
    h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h4 {
        color: #fafafa !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin: 20px 0 12px;
    }

    h5 {
        color: #fafafa !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        margin: 20px 0 12px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/business/serendipia-logo-2.jpeg" alt="Serendipia" style="width: 300px; border-radius: 20px;" />
            </div>
        </a>
    </div>
    <h3>“Existe un lugar diferente a lo tradicional”</h3>
    <h4>Reservas: 953 279 850</h4>
    <h5>4:00 pm. a 11:30 pm.</h5>
    <div class="spacer"></div>
    <div class="time-counter form-row other-style">
        <div class="hours count col">
            <a href="./assets/docs/business/serendipia-comida.pdf"
                target="_blank" class="btn btn-success" style="margin-bottom:10px">Ver carta comidas y cócteles</a>
        </div>
        <div class="hours count col">
            <a href="./assets/docs/business/serendipia-bebida.pdf"
                target="_blank" class="btn btn-success" style="margin-bottom:10px">Ver carta bebidas</a>
        </div>
    </div>
</div>