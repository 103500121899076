<style>
    h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .mat-icon {
        font-size: 18px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/ga-logo-500.png" alt="Gazelle App" style="width: 150px" />
            </div>
        </a>
    </div>
    <h3>Misión</h3>
    <p class="mb-4">
        Brindar un servicio óptimo de taxi privado, soportados con un equipo humano competente y comprometido,
        que cuenta con una plataforma tecnológica de última generación, exaltando la excelencia, comodidad,
        responsabilidad y calidad en el servicio, con el objetivo de generar beneficios a nuestros usuarios
        clientes, socios conductores y aliados.
    </p>
    <h3>Visión</h3>
    <p>
        Ser reconocidos a nivel regional y nacional, como una empresa líder e innovadora en el transporte público.
        Brindando un servicio seguro, puntual y eficaz. Manteniendo este compromiso con nuestros usuarios clientes
        y con el medio ambiente.
    </p>
    <a routerLink="/" class="btn btn-primary btn-sm mt-4 mb-0" style="margin-bottom:10px">
        <mat-icon>arrow_back</mat-icon>
    </a>
</div>