import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-terms',
  templateUrl: './customer-terms.component.html',
  styleUrls: ['./customer-terms.component.scss']
})
export class CustomerTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
