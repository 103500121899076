<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<div class="form-body no-side">
  <canvas id="pagebg" resize=""></canvas>
  <div class="row">
    <div class="form-holder">
      <div class="form-content">
        <div class="form-items">

          <router-outlet></router-outlet>

          <div class="form-box">
            <div class="spacer"></div>
            <hr>
            <div class="time-counter form-row other-style">
              <div class="days count col" style="margin-top: 10px;">
                <a routerLink="/about-us"
                  style="margin-bottom:10px;text-decoration:none;color:#f9f9f9;font-weight:600;">Nosotros
                </a>
              </div>
              <div class="hours count col" style="margin-top: 10px;">
                <a routerLink="/service"
                  style="margin-bottom:10px;text-decoration:none;color:#f9f9f9;font-weight:600;">Objetivo
                </a>
              </div>
              <div class="hours count col" style="margin-top: 10px;">
                <a routerLink="/customers"
                  style="margin-bottom:10px;text-decoration:none;color:#f9f9f9;font-weight:600;">Clientes
                </a>
              </div>
              <div class="hours count col" style="margin-top: 10px;">
                <a routerLink="/drivers"
                  style="margin-bottom:10px;text-decoration:none;color:#f9f9f9;font-weight:600;">Conductores
                </a>
              </div>
              <div class="hours count col" style="margin-top: 10px;">
                <a routerLink="/privacy"
                  style="margin-bottom:10px;text-decoration:none;color:#f9f9f9;font-weight:600;">Privacidad
                </a>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <a href="mailto:contacto@gazelledriver.com" style="text-decoration: none;">
              <p style="font-size:16px;color:#fafafa;">contacto@gazelledriver.com</p>
            </a>
            <div class="other-links no-bg-icon">
              <a href="https://facebook.com/gazelledriverapp" class="m-2" target="_blank">
                <mat-icon>facebook</mat-icon>
              </a>
              <a href="https://instagram.com/gazelledriverapp" class="m-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                  role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
                  <path
                    d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3S645.3 585.4 645.3 512S585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9S717.1 398.5 717.1 512S625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9s47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"
                    fill="#fafafa" />
                </svg>
              </a>
              <a href="https://api.whatsapp.com/send?phone=51913382210&text=Hola%20quiero%20hacer%20una%20consulta"
                class="m-2" target="_blank">
                <mat-icon>whatsapp</mat-icon>
              </a>
              <a href="https://m.me/gazelledriverapp" class="m-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                  role="img" width="1.8em" height="1.8em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                  <g fill="#fafafa">
                    <path
                      d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76s-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459l-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
                  </g>
                </svg>
              </a>
            </div>
            <div class="other-links no-bg-icon">
              <p style="font-size:14px;color:#b8b8b8;">
                Powered by <a href="https://indexo.io" target="_blank"
                  style="font-size:14px;font-weight:500;color:#b8b8b8;">Indexo Corp.</a>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>