// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    config: {
      apiKey: "AIzaSyA2DtQeOLhuDXbPJLvw8lIqAmU5usDi_zo",
      authDomain: "gazelle-driver-app.firebaseapp.com",
      projectId: "gazelle-driver-app",
      storageBucket: "gazelle-driver-app.appspot.com",
      messagingSenderId: "470817546177",
      appId: "1:470817546177:web:cd8fdeca7ae1b3d05a1940",
      measurementId: "G-1L70WNRF4C"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
