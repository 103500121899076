<style>
    h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 25px;
    }
</style>

<div class="form-box">
    <div class="website-logo-inside">
        <a routerLink="/">
            <div>
                <img src="./assets/img/ga-logo-500.png" alt="Gazelle App" style="width: 200px" />
            </div>
        </a>
    </div>
    <h3>¡Tu App de taxi rápido, confiable y elegante!</h3>
    <p>
        Viaja seguro y tranquilo. Nuestro servicio de taxi rápido, confiable y elegante ahora en la palma de tu
        mano. ¡Descárgate el App y recibe muchos beneficios!
    </p>
    <a href="https://play.google.com/store/apps/details?id=com.indexo.gazelle.customer" target="_blank" >
        <img src="./assets/img/google-play.png" class="mt-3 mb-3" alt="Gazelle App" style="width: 250px" />
    </a>
    <div class="spacer"></div>
    <div class="time-counter form-row other-style">
        <div class="hours count col">
            <a href="https://api.whatsapp.com/send?phone=51913382210&text=Hola%2C%20quiero%20consultar%20un%20viaje"
                target="_blank" class="btn btn-secondary" style="margin-bottom:10px">Consulta un viaje</a>
        </div>
    </div>
</div>